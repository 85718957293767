// @ts-check
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";

import GroupIcon from "@material-ui/icons/Group";

import { BackNavigationComponent } from "../../../../core/routes/components/back-navigation-component";

import { LoadingComponent } from "../components/loading-component";
import Grid from "@material-ui/core/Grid";
import { TextBackgroundComponent } from "../../../locations/presentation/components/text-background-component";
import { GuardiansInviteComponent } from "./guardians-invite-page";
import { getLocationsAction } from "../../../locations/presentation/redux/actions/location-actions";
import { GuardianListComponent } from "../components/guardians-list-component";

import DI from "typedi";
import { GuardianActions } from "../redux/actions/guardian-actions";
const guardianActions = DI.get(GuardianActions);

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  heading: {
    margin: theme.spacing(2),
  },
}));

export default function GuardiansPage() {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(guardianActions.getGuardiansAction());
  }, [dispatch]);

  // get list of locations for invite use
  useEffect(() => {
    dispatch(getLocationsAction());
  }, [dispatch]);

  const { guardians, loading, error } = useSelector((state) => state.guardians);
  const { me } = useSelector((state) => state.me);

  const [view, setView] = useState("guardians");

  if (error) return <div>Error! {error.message}</div>;

  // return <Appointments guardians={guardians} />;
  return (
    <>
      <React.Fragment>
        <CssBaseline />
        <Container className={classes.container}>
          {loading && <LoadingComponent />}
          {!loading && (
            <>
              <BackNavigationComponent
                page="Guardians"
                path={[
                  {
                    page: "Home",
                    uri: "/",
                  },
                ]}
              />

              <Grid container spacing={2} justify="center">
                <Grid item xs={12} md={12}>
                  <TextBackgroundComponent text={"Health Professionals"} />
                </Grid>
                <Grid item xs={12} md={8}>
                  <>
                    <div>
                      <ul className="uk-child-width-expand" data-uk-tab>
                        <li
                          className={
                            (view === `guardians` && "uk-active") || ""
                          }
                        >
                          {/* eslint-disable-next-line */}
                          <a onClick={() => setView("guardians")}>
                            <small className="uk-display-block uk-padding-small">
                              <span className="uk-margin-small-right">
                                <GroupIcon fontSize={"small"} />
                              </span>
                              <b>Guardians</b>
                            </small>
                          </a>
                        </li>

                        <li
                          className={(view === `invites` && "uk-active") || ""}
                        >
                          {/* eslint-disable-next-line */}
                          <a onClick={() => setView("invites")}>
                            <small className="uk-display-block uk-padding-small">
                              <span className="uk-margin-small-right">
                                <GroupIcon fontSize={"small"} />
                              </span>

                              <b>Invite</b>
                            </small>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </>

                  {view === "guardians" && (
                    <div className="uk-margin">
                      {/* <SearchDriversComponent /> */}
                      {/* <GuardiansMapComponent guardians={guardians} /> */}
                      <GuardianListComponent guardians={guardians} />
                    </div>
                  )}

                  {view === "invites" && (
                    <div className="uk-margin">
                      <GuardiansInviteComponent
                        organization={me.organization}
                      />
                    </div>
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </Container>
      </React.Fragment>
    </>
  );
}
