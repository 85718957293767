// @ts-check
import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { makeStyles } from "@material-ui/core/styles";

import * as HighchartStock from "highcharts/highstock";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0),
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  sectionHeading: {
    margin: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    cursor: "pointer",
  },
  container: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  clientLogo: {
    height: "8rem",
    width: "8rem",
    objectFit: "contain",
  },
  icon: {
    fontSize: "4em",
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  heading: {
    margin: theme.spacing(2),
  },
}));

window.Highcharts = Highcharts; //this line did the magic
window.Highcharts = HighchartStock; //this line did the magic

const color = {
  RED: "#D51616",
  YELLOW: "#FE9101",
  GREEN: "#388E3C",
  GREY: "#8c8c8c",
  ORANGE: "#A92704",
};

export function DornutStats({ data, name }) {
  let bigCities = data.filter((x) => x[1] !== 0);

  const config = {
    chart: {
      type: "pie",
      height: "308",
      options3d: {
        enabled: false,
        alpha: 45,
      },
    },
    title: {
      text: name,
    },
    subtitle: {
      text: null,
    },
    plotOptions: {
      pie: {
        // colors: [color.RED, color.YELLOW, color.GREEN],
        innerSize: 100,
        depth: 45,
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    series: [
      {
        name: "Amount",
        data: data.filter((x) => x[1] > 0),
      },
    ],
  };

  return (
    <>
      <HighchartsReact highcharts={Highcharts} options={config} />
    </>
  );
}

function mapData([x, y]) {
  return {
    name: x,
    x: x,
    y: y,
    actualY: y,
    color: "#E6496F",
    // color: y <= 0 ? '#63CCA6' : '#E6496F'
  };
}

export function BarChart({ xy, title, subtitle, yName }) {
  const config = {
    chart: {
      // alignTicks: false,
      // zoomType: 'x',
      type: "columnrange",
      height: "508",
    },

    rangeSelector: {
      selected: 1,
    },

    title: {
      text: title,
    },
    // xAxis: {
    //   title: {
    //     text: null
    //   },
    //   // crosshair: false,
    //   // width: '100%',
    //   // ordinal: false,
    //   type: 'datetime',
    //   dateTimeLabelFormats: {
    //     // millisecond: '%H:%M:%S.%L',
    //     // second: '%H:%M:%S',
    //     // minute: '%H:%M',
    //     // hour: '%H:%M',
    //     // day: '%e. %b',
    //     // week: '%e. %b',
    //     month: "%b '%y",
    //     year: '%Y'
    //   }
    //   // minorTickInterval: 'auto',
    //   // showLastLabel: false,
    //   // startOnTick: true,
    //   // endOnTick: false
    // },
    // yAxis: [
    //   {
    //     ordinal: false,
    //     title: {
    //       text: yName
    //     },
    //     labels: {
    //       overflow: 'justify'
    //     },
    //     showFirstLabel: false
    //   }
    // ],

    series: [
      {
        // showInLegend: true,
        type: "column",
        name: "Total Fees",
        // data: stock1,

        data: xy.fees
          .sort((a, b) => {
            return a[0] - b[0];
          })
          .map(mapData),

        tooltip: {
          valueDecimals: 2,
        },
        dataGrouping: {
          units: [
            // ['week', [1]],
            ["month", [1, 3, 6]],
          ],
        },
      },
      {
        showInLegend: true,
        type: "column",
        name: "Booking Fees",
        // data: stock1,
        data: xy.booking.sort((a, b) => {
          return a[0] - b[0];
        }),
        tooltip: {
          valueDecimals: 2,
        },

        dataGrouping: {
          units: [
            // ['week', [1]]
            ["month", [1, 3, 6]],
          ],
        },
      },
      {
        showInLegend: true,
        type: "column",
        name: "Service Fees",
        // data: stock1,
        // color: '#63CCA6',

        data: xy.service.sort((a, b) => {
          return a[0] - b[0];
        }),
        tooltip: {
          valueDecimals: 2,
        },
        smoothed: true,

        dataGrouping: {
          units: [
            // ['week', [1]]
            ["month", [1, 3, 6]],
          ],
        },
      },

      {
        showInLegend: true,
        type: "column",
        name: "Cancellation Fees",
        // data: stock1,
        data: xy.cancellation.sort((a, b) => {
          return a[0] - b[0];
        }),
        tooltip: {
          valueDecimals: 2,
        },

        dataGrouping: {
          units: [
            // ['week', [1]]
            ["month", [1, 3, 6]],
          ],
        },
      },
      {
        showInLegend: true,
        type: "column",
        name: "Reversed Fees",
        // data: stock1,
        data: xy.reversal.sort((a, b) => {
          return a[0] - b[0];
        }),
        tooltip: {
          valueDecimals: 2,
        },

        dataGrouping: {
          units: [
            // ['week', [1]]
            ["month", [1, 3, 6]],
          ],
        },
      },
    ],
  };
  return (
    <div>
      <HighchartsReact
        highcharts={HighchartStock}
        options={config}
        constructorType={"stockChart"}
      />
    </div>
  );
}

export function BarChart1({ xy, title, subtitle, yName }) {
  const config = {
    chart: {
      type: "column",
      zoomType: "x",
      height: "408",
    },
    rangeSelector: {
      selected: 1,
    },
    title: {
      text: title,
    },
    subtitle: {
      text: null,
    },
    // xAxis: {
    //   title: {
    //     text: null
    //   },
    //   crosshair: true,
    //   width: '100%',
    //   ordinal: false,
    //   type: 'datetime',
    //   dateTimeLabelFormats: {
    //     // millisecond: '%H:%M:%S.%L',
    //     // second: '%H:%M:%S',
    //     // minute: '%H:%M',
    //     // hour: '%H:%M',
    //     // day: '%e. %b',
    //     week: '%e. %b',
    //     month: "%b '%y",
    //     year: '%Y'
    //   },
    //   // minorTickInterval: 'auto',
    //   showLastLabel: true,
    //   startOnTick: true,
    //   endOnTick: false
    // },
    // yAxis: [
    //   {
    //     // min: 0,
    //     ordinal: false,
    //     title: {
    //       text: yName
    //     },
    //     labels: {
    //       overflow: 'justify'
    //     },
    //     showFirstLabel: false
    //   },
    //   {
    //     // right y axis
    //     linkedTo: 0,
    //     gridLineWidth: 0,
    //     opposite: true,
    //     title: {
    //       text: null
    //     },
    //     labels: {
    //       align: 'right',
    //       x: -3,
    //       y: 16,
    //       format: '{value:.,0f}'
    //     },
    //     showFirstLabel: false
    //   }
    // ],

    legend: {
      align: "left",
      verticalAlign: "top",
      borderWidth: 0,
    },
    tooltip: {
      shared: true,
      crosshairs: true,
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        showInLegend: true,
        type: "column",

        data: xy.payment.sort((a, b) => {
          return a[0] - b[0];
        }),
        tooltip: {
          valueDecimals: 2,
        },

        dataGrouping: {
          units: [
            ["week", [1]],
            ["month", [1, 3, 6]],
          ],
        },
        name: "Revenue",
      },
    ],
  };
  return (
    <div>
      <HighchartsReact
        highcharts={HighchartStock}
        options={config}
        constructorType={"stockChart"}
      />
    </div>
  );
}
