// @ts-check
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import { Button, CircularProgress } from "@material-ui/core";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import { uploadLogoAction } from "../redux/actions/organization-actions";
import { getMeSilentAction } from "../../../me/presentation/redux/actions/me-actions";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  clientLogo: {
    height: "8rem",
    width: "8rem",
    objectFit: "contain",
    margin: 0,
  },
}));

export function UploadLogoComponent({ organization }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { loading } = useSelector((state) => state.upload);

  const [preview, setPreview] = useState(organization.logo);
  const [file, setFile] = useState();

  const handleImageChange = (e) => {
    const _file = e.target.files[0];
    // @ts-ignore
    setPreview(URL.createObjectURL(_file));
    setFile(_file);
  };

  const cancel = () => {
    setFile(undefined);
    setPreview(organization.logo);
  };

  const uploadSuccess = () => {
    setFile(undefined);
    dispatch(getMeSilentAction());
  };

  const submit = () => {
    if (file) {
      const props = { uploadSuccess };
      dispatch(uploadLogoAction(organization.id, file, props));
    }
  };

  return (
    <div className={classes.root}>
      <img className={classes.clientLogo} src={preview} alt="logo" />

      <div>
        <input
          accept="image/*"
          className={classes.input}
          id="icon-button-file"
          type="file"
          onChange={handleImageChange}
        />
      </div>

      {!loading && (
        <>
          {!file && (
            <label htmlFor="icon-button-file">
              <IconButton
                color="primary"
                aria-label="upload organization logo"
                component="span"
              >
                <PhotoCamera />
              </IconButton>
            </label>
          )}

          {file && (
            <>
              <label>
                <IconButton
                  color="primary"
                  aria-label="upload organization logo"
                  component="span"
                  onClick={cancel}
                >
                  <CancelOutlinedIcon />
                </IconButton>
              </label>
              <label>
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  size="small"
                  onClick={submit}
                >
                  Upload
                </Button>
              </label>
            </>
          )}
        </>
      )}

      {loading && (
        <label>
          <IconButton
            color="primary"
            aria-label="upload organization logo"
            component="span"
          >
            <CircularProgress color="inherit" size={20} />
          </IconButton>
        </label>
      )}
    </div>
  );
}
