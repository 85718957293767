// @ts-check
import React from "react";

import { Formik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";

import { MenuItem, TextField } from "@material-ui/core";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { IconButton, ListItemSecondaryAction } from "@material-ui/core";
import LocationOnIcon from "@material-ui/icons/LocationOn";

import { LocationAddressComponent } from "../../../../core/utils/location-address-component";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  sectionHeading: {
    margin: theme.spacing(2),
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  formButtons: {
    marginTop: theme.spacing(0),
  },
  errorText: {
    color: "#f44336",
    textAlign: "center",
    display: "block",
    margin: theme.spacing(3),
  },
  paperPlain: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    cursor: "pointer",
  },
}));

function AddUserLocationForm(props) {
  const classes = useStyles();

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
    props;

  const { locations } = useSelector((state) => state.locations);

  const locationsList = locations.map((location) => ({
    value: location.id,
    label: location.name,
  }));

  /**
   * @param {string} name
   */
  const formikProps = (name, initialValue = "") => ({
    name: name,
    value: typeof values[name] !== "undefined" ? values[name] : initialValue,
    onChange: handleChange,
    onBlur: handleBlur,
    error: touched[name] && Boolean(errors[name]),
    helperText: touched[name] ? errors[name] : "",
  });

  const location = values.location
    ? locations.filter((x) => x.id === values.location)[0]
    : null;

  return (
    <form
      className={classes.root}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <TextField
        {...formikProps("location")}
        id="outlined-select-currency"
        select
        label="Location"
        variant="outlined"
        fullWidth={true}
      >
        {locationsList.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      {values.location && (
        <>
          <div className="uk-margin">
            <List className={classes.paperPlain}>
              <ListItem>
                <ListItemText
                  primary={`${location.name}`}
                  secondary={null}
                ></ListItemText>

                <ListItemSecondaryAction>
                  <IconButton edge="end">
                    <LocationOnIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </div>

          <LocationAddressComponent location={location} />
        </>
      )}

      <div className={classes.formButtons}>
        <>
          <div className="uk-margin  uk-margin-top">
            <button
              className="uk-button uk-button-primary uk-width-1-1"
              type="submit"
            >
              Grant Location Access
            </button>
          </div>
        </>
      </div>
    </form>
  );
}

const validationSchema = Yup.object({
  location: Yup.string().required("Location is required"),
});

export function AddUserLocationFormComponent({ grant }) {
  const values = {
    location: "",
  };

  const submit = async (data, actions) => {
    const { location } = data;

    grant(location, ["frontdesk"], actions.resetForm);
  };

  return (
    <div className="uk-margin">
      <Formik
        initialValues={values}
        validationSchema={validationSchema}
        onSubmit={submit}
      >
        {(props) => <AddUserLocationForm {...props} />}
      </Formik>
    </div>
  );
}
