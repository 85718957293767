import { Organization } from "../../../organization/domain/entities/organization-entity";

export class Me {
  id: string;
  title: string;
  name: string;
  email: string;
  image: string;
  gender: string;
  contact: string;
  organization: Organization;

  constructor(args: any) {
    this.id = args.id;
    this.title = args.title;
    this.name = args.name;
    this.email = args.email;
    this.image = args.image;
    this.gender = args.gender;
    this.contact = args.contact;
    this.organization = new Organization(args.organization);
  }
}
