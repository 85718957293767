import { Service } from "typedi";
import { Guardian } from "../entities/guardian-entity";
import { IGuardianRepository } from "../repositories/guardian-repository";

export interface IGuardianUsecase {
  GetOrganizationGuardians(location_id?: string): Promise<Guardian[]>;
  GetOrganizationGuardian(guardian_id: string): Promise<Guardian>;
  SearchGuardians(keyword: string): Promise<Guardian[]>;
  InviteGuardianToOrganization(
    guardian_id: string,
    location_id: string
  ): Promise<Guardian[]>;
  RemoveGuardianFromOrganization(guardian_id: string): Promise<Guardian>;
  CancelGuardianInvitation(guardian_id: string): Promise<Guardian>;
}

@Service()
export class GuardianUsecase implements IGuardianUsecase {
  readonly guardianRepository: IGuardianRepository;

  constructor(guardianRepository: IGuardianRepository) {
    this.guardianRepository = guardianRepository;
  }

  GetOrganizationGuardians(location_id?: string): Promise<Guardian[]> {
    return this.guardianRepository.GetOrganizationGuardians(location_id);
  }

  GetOrganizationGuardian(guardian_id: string): Promise<Guardian> {
    return this.guardianRepository.GetOrganizationGuardian(guardian_id);
  }

  SearchGuardians(keyword: string): Promise<Guardian[]> {
    return this.guardianRepository.SearchGuardians(keyword);
  }

  InviteGuardianToOrganization(
    guardian_id: string,
    location_id: string
  ): Promise<Guardian[]> {
    return this.guardianRepository.InviteGuardianToOrganization(
      guardian_id,
      location_id
    );
  }

  RemoveGuardianFromOrganization(guardian_id: string): Promise<Guardian> {
    return this.guardianRepository.RemoveGuardianFromOrganization(guardian_id);
  }

  CancelGuardianInvitation(guardian_id: string): Promise<Guardian> {
    return this.guardianRepository.CancelGuardianInvitation(guardian_id);
  }
}
