// @ts-check
import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { COLOURS } from "../../../../core/constants";

import { LoginFormComponent } from "../components/login-form-component";
import config from "../../../../core/config/config";
import { getOrganizationsAction } from "../redux/actions/auth-actions";

const logoText = `https://storage.googleapis.com/guardian-health-brand-identity/medical-guardians-text-dull-logo.png`;

const styles = {
  logoIcon: {
    height: "3.5em",
    objectFit: "contain",
  },
  logoText: {
    height: "1.5em",
    objectFit: "contain",
  },
  strikeSeparator: {
    borderTop: `1px solid ${COLOURS.TIARA}`,
    position: "relative",
    margin: "25px 0 10px",
    textAlign: "center",
  },
  strikeHeading: {
    backgroundColor: COLOURS.BACKGROUND,
    padding: "0 .5em",
    position: "relative",
    color: COLOURS.DECO,
    top: "-.85em",
  },
  strikeBlock: {
    display: "flow-root",
  },
  url: {
    fontWeight: 400,
    textDecoration: "none",
    color: "#2a92c7",
    cursor: "pointer",
  },
  phone: {
    paddingLeft: "4rem !important",
  },
};

/**
 * Method to render a stricked separation
 * @param {object} param
 * @param {string} param.description
 */
export function StrikedSeparator({ description }) {
  return (
    <div
      // @ts-ignore
      style={styles.strikeSeparator}
    >
      <span
        aria-label="or"
        // @ts-ignore
        style={styles.strikeHeading}
      >
        {description}
      </span>
    </div>
  );
}

export default function Login() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrganizationsAction(""));
  }, [dispatch]);
  const { loading } = useSelector((state) => state.login);

  const { uri } = config;

  return (
    <div className="uk-container uk-margin">
      <div className="uk-width-2-5@m uk-width-3-5@s uk-width-1-1 uk-align-center">
        <div className="uk-text-center uk-margin uk-margin-medium-top">
          {/* <img
            src={logoIcon}
            alt="Guardian Health"
            // @ts-ignore
            style={styles.logoIcon}
          /> */}
          <br />
          <br />

          <img
            src={logoText}
            alt="Guardian Health Text Logo"
            // @ts-ignore
            style={styles.logoText}
          />

          <small className="uk-display-block uk-margin-small">
            Made For Organizations
          </small>
        </div>

        <LoginFormComponent />

        {!loading && (
          <>
            <div className="uk-text-center uk-margin uk-margin-remove-bottom">
              <small>
                <a
                  href={`${uri.health_app}/recover/password`}
                  target="_blank"
                  // to="/recover/password"
                  rel="noopener noreferrer"
                  style={{
                    // color: 'rgb(237, 28, 36)',
                    fontWeight: 400,
                    // textDecoration: 'none'
                  }}
                >
                  Forgot password?
                </a>
              </small>
            </div>

            <div className="uk-margin-remove" style={styles.strikeBlock}>
              <StrikedSeparator description={"or"} />
            </div>

            <div className="uk-margin-bottom">
              <div className="uk-card uk-card-default uk-card-hover uk-card-body uk-border-rounded uk-padding-small">
                <div
                  className="uk-grid uk-grid-small  uk-child-width-expand"
                  data-uk-grid
                >
                  <div className="uk-margin-">
                    {/* eslint-disable-next-line */}
                    <a
                      className="uk-button uk-button-primary uk-width-1-1 uk-button-medium"
                      href={uri.frontdesk_app}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        border: "1px solid #fd8361",
                        background: "unset",
                        color: "#fd8361",
                      }}
                    >
                      FrontDesk Portal
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
