import { User } from "../entities/user-entity";
import { IUserRepository } from "../repositories/iuser-repository";

export interface IUserUsecase extends IUserRepository {}

export class UserUsecase implements IUserUsecase {
  userRepository: IUserRepository;

  constructor(ur: IUserRepository) {
    this.userRepository = ur;
  }

  FindUsers(keyword: string): Promise<User[]> {
    return this.userRepository.FindUsers(keyword);
  }

  GetUsers(location_id?: string): Promise<User[]> {
    return this.userRepository.GetUsers(location_id);
  }

  GetUser(organization_user_id: string): Promise<User> {
    return this.userRepository.GetUser(organization_user_id);
  }

  InviteUser(
    user_id: string,
    administrator: boolean,
    permissions: any[]
  ): Promise<User[]> {
    return this.userRepository.InviteUser(user_id, administrator, permissions);
  }

  RemoveUser(organization_user_id: string): Promise<boolean> {
    return this.userRepository.RemoveUser(organization_user_id);
  }

  UpdateUserPermissions(
    organization_user_id: string,
    level: string,
    type: string,
    permission: any
  ): Promise<User> {
    return this.userRepository.UpdateUserPermissions(
      organization_user_id,
      level,
      type,
      permission
    );
  }
}
