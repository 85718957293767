// @ts-check
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { removeUserFromOrganizationAction } from "../redux/actions/user-actions";

export function RemoveUserFromOrganizationComponent({
  user_id,
  openBackdrop,
  closeBackdrop,
  showSnackbar,
}) {
  const history = useHistory();
  const dispatch = useDispatch();

  const action = () => {
    openBackdrop();

    const props = { history, closeBackdrop, showSnackbar };
    dispatch(removeUserFromOrganizationAction(user_id, props));
  };

  return (
    <div className="uk-margin">
      <button
        className={`uk-button uk-button-danger uk-width-1-1`}
        onClick={action}
      >
        Remove From Organization
      </button>
    </div>
  );
}
