// @ts-check
import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { getOrganizationAction } from "../redux/actions/organization-actions";

import { makeStyles } from "@material-ui/core/styles";

import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";

import { Paper } from "@material-ui/core";

import Grid from "@material-ui/core/Grid";
import { LoadingComponent } from "../components/loading-component";
import { UploadLogoComponent } from "../components/upload-logo-component";
import { ClientComponent } from "../components/organization-component";

import { BackNavigationComponent } from "../../../../core/routes/components/back-navigation-component";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0),
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  sectionHeading: {
    margin: theme.spacing(3),
  },
  clientHeader: {
    textAlign: "center",
  },
  clientLogo: {
    height: "10rem",
    width: "10rem",
    objectFit: "contain",
  },
  large: {
    height: "8rem",
    width: "8rem",
    objectFit: "contain",
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    cursor: "pointer",
  },
}));

function CenteredGrid({ organization }) {
  const classes = useStyles();

  return (
    <Grid container spacing={2} justify="center">
      <Grid item xs={12} md={12}>
        <Paper className={classes.paper} elevation={0}>
          <UploadLogoComponent organization={organization} />
        </Paper>
      </Grid>

      <Grid item xs={12} md={8}>
        <ClientComponent organization={organization} />
      </Grid>
    </Grid>
  );
}

export default function OrganizationPage() {
  const classes = useStyles();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrganizationAction());
  }, [dispatch]);

  const { organization, loading, success } = useSelector(
    (state) => state.organization
  );

  return (
    <>
      <React.Fragment>
        <CssBaseline />
        <Container className={classes.container}>
          {loading && <LoadingComponent />}
          {!loading && success && (
            <>
              <BackNavigationComponent
                page="Organization"
                uri="/"
                path={[
                  {
                    page: "Home",
                    uri: "/",
                  },
                ]}
              />
              <CenteredGrid organization={organization} />
            </>
          )}
        </Container>
      </React.Fragment>
    </>
  );
}
