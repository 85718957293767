import { Container } from "typedi";
import { Api, IApi } from "./core/api/api";
import { BillingRepositoryImpl } from "./features/billing/data/repositories/billing-repository-impl";
import { BillingRepository } from "./features/billing/domain/repositories/billing-repository";
import {
  BillingUsecase,
  BillingUsecaseImpl,
} from "./features/billing/domain/usecases/billing-usecase";
import { BillingActions } from "./features/billing/presentation/redux/actions/billing-actions";

const IOC = (): void => {
  // accounts
  Container.set<BillingRepository>(
    BillingRepositoryImpl,
    Container.get<IApi>(Api)
  );

  Container.set<BillingUsecase>(
    BillingUsecaseImpl,
    Container.get<BillingRepository>(BillingRepositoryImpl)
  );
  Container.get(BillingActions);
  Container.get(Api);
};

export default IOC;
