import { Dispatch } from "redux";

import { GuardianRepository } from "../../../data/repositories/guardian-repository-impl";
import {
  GuardianUsecase,
  IGuardianUsecase,
} from "../../../domain/usecases/guardian-usecase";

import {
  GUARDIAN_LOAD_FAILURE,
  GUARDIAN_LOAD_REQUEST,
  GUARDIAN_LOAD_SUCCESS,
} from "../types/guardian-types";
import {
  GUARDIANS_LOAD_FAILURE,
  GUARDIANS_LOAD_REQUEST,
  GUARDIANS_LOAD_SUCCESS,
} from "../types/guardians-types";
import {
  FIND_USERS_LOAD_FAILURE,
  FIND_USERS_LOAD_REQUEST,
  FIND_USERS_LOAD_SUCCESS,
} from "../../../../users/presentation/redux/types/find-users-types";
import { Api, IApi } from "../../../../../core/api/api";
import { Service } from "typedi";

@Service()
export class GuardianActions {
  private readonly guardianUsecase: IGuardianUsecase;

  constructor(guardianUsecase: IGuardianUsecase) {
    const api: IApi = new Api();

    const guardianRepository = new GuardianRepository(api);
    this.guardianUsecase = new GuardianUsecase(guardianRepository);
  }

  getGuardiansAction = (location_id?: string) => {
    return async (dispatch: Dispatch) => {
      dispatch({ type: GUARDIANS_LOAD_REQUEST });

      try {
        const result = await this.guardianUsecase.GetOrganizationGuardians(
          location_id
        );

        dispatch({ type: GUARDIANS_LOAD_SUCCESS, payload: result });
      } catch (error) {
        dispatch({ type: GUARDIANS_LOAD_FAILURE, error });
      }
    };
  };

  getGuardianAction = (guardian_id: string) => {
    return async (dispatch: Dispatch) => {
      dispatch({ type: GUARDIAN_LOAD_REQUEST });

      try {
        const result = await this.guardianUsecase.GetOrganizationGuardian(
          guardian_id
        );

        dispatch({ type: GUARDIAN_LOAD_SUCCESS, payload: result });
      } catch (error) {
        dispatch({ type: GUARDIAN_LOAD_FAILURE, error });
      }
    };
  };

  inviteGuardianAction = (
    guardian_id: string,
    location_id: string,
    props: any
  ) => {
    return async (dispatch: Dispatch) => {
      try {
        const result = await this.guardianUsecase.InviteGuardianToOrganization(
          guardian_id,
          location_id
        );

        dispatch({ type: GUARDIANS_LOAD_SUCCESS, payload: result });

        props.resetSearch();
        props.closeBackdrop();
        props.showSnackbar(
          "success",
          "Health professional invited to organization"
        );
      } catch (error) {
        props.closeBackdrop();
        props.showSnackbar("error", error.message);
      }
    };
  };

  removeGuardianFromOrganizationAction = (guardian_id: string, props: any) => {
    return async (dispatch: Dispatch) => {
      try {
        await this.guardianUsecase.RemoveGuardianFromOrganization(guardian_id);

        props.closeBackdrop();
        props.history.push("/guardians");
      } catch (error) {
        props.closeBackdrop();
      }
    };
  };

  cancelGuardianInviteOrganizationAction = (
    guardian_id: string,
    props: any
  ) => {
    return async (dispatch: Dispatch) => {
      try {
        await this.guardianUsecase.CancelGuardianInvitation(guardian_id);

        props.closeBackdrop();
        props.history.push("/guardians");
      } catch (error) {
        props.closeBackdrop();
      }
    };
  };

  searchGuardiansAction = (keyword: string) => {
    return async (dispatch: Dispatch) => {
      dispatch({ type: FIND_USERS_LOAD_REQUEST });

      try {
        const result = await this.guardianUsecase.SearchGuardians(keyword);

        dispatch({ type: FIND_USERS_LOAD_SUCCESS, payload: result });
      } catch (error) {
        dispatch({ type: FIND_USERS_LOAD_FAILURE, error });
      }
    };
  };
}
