// @ts-check
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import {
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";

import Button from "@material-ui/core/Button/Button";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { updateUserPermissionAction } from "../redux/actions/user-actions";

import { SnackbarComponent } from "../../../../core/utils/snackbar-component";
import { BackdropComponent } from "../../../../core/utils/backdrop-component";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(2),
  },
  inline: {
    display: "inline",
  },
  heading: {
    margin: theme.spacing(2),
  },
  fab: {
    width: "100%",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  granted: {
    color: "#4caf50",
  },
}));

export function AdminPermissionsComponent({ user }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [backdrop, setBackdrop] = useState(false);

  const openBackdrop = () => {
    setBackdrop(true);
  };

  const closeBackdrop = () => {
    setBackdrop(false);
  };

  const [snackbar, setSnackbar] = useState(false);
  const [message, setMessage] = useState({
    status: "success",
    description: "N/A",
  });

  const showSnackbar = (status, description) => {
    setSnackbar(true);
    setMessage({
      status,
      description,
    });
  };

  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbar(false);
  };

  const grant = () => {
    openBackdrop();
    const props = { closeBackdrop, showSnackbar };
    const level = "administrator";
    const type = "grant";
    dispatch(
      updateUserPermissionAction(user.id, level, type, undefined, props)
    );
  };

  const revoke = () => {
    openBackdrop();
    const props = { closeBackdrop, showSnackbar };
    const level = "administrator";
    const type = "revoke";
    dispatch(
      updateUserPermissionAction(user.id, level, type, undefined, props)
    );
  };

  return (
    <>
      <List className={classes.root}>
        <ListItem>
          <ListItemIcon>
            {user.administrator && (
              <FiberManualRecordIcon className={classes.granted} />
            )}
            {!user.administrator && <FiberManualRecordIcon color="error" />}
          </ListItemIcon>
          <ListItemText
            primary={<small>Admin rights</small>}
            secondary={null}
          ></ListItemText>

          <ListItemSecondaryAction>
            {user.administrator && (
              <Button
                type="button"
                variant="outlined"
                size="small"
                color="secondary"
                onClick={revoke}
              >
                Revoke
              </Button>
            )}
            {!user.administrator && (
              <Button
                type="button"
                variant="contained"
                size="small"
                color="secondary"
                onClick={grant}
              >
                Grant
              </Button>
            )}
          </ListItemSecondaryAction>
        </ListItem>
      </List>

      <>
        <BackdropComponent open={backdrop} />

        <SnackbarComponent
          open={snackbar}
          onClose={closeSnackbar}
          severity={message.status}
          message={message.description}
        />
      </>
    </>
  );
}
