class Location {
  type: String;
  coordinates: number[];

  constructor(lat: number, lng: number) {
    this.type = "Point";
    this.coordinates = [lng, lat];
  }
}

class Geolocation {
  lat: number;
  lng: number;

  constructor(lat: number, lng: number) {
    this.lat = lat;
    this.lng = lng;
  }
}

interface AddressInterface {
  full_address: string;
  suburb: string;
  city: string;
  location: Location;
  geolocation: Geolocation;
  place_id: string;
}

export class Address implements AddressInterface {
  full_address: string;
  suburb: string;
  city: string;
  location: Location;
  geolocation: Geolocation;
  place_id: string;

  constructor(
    full_address: string,
    suburb: string,
    city: string,
    geolocation: Geolocation,
    place_id: string
  ) {
    this.full_address = full_address;
    this.suburb = suburb;
    this.city = city;
    this.full_address = full_address;
    this.location = new Location(geolocation.lat, geolocation.lng);
    this.geolocation = new Geolocation(geolocation.lat, geolocation.lng);
    this.place_id = place_id;
  }
}

export class LocationEntity {
  id: String;
  name: String;
  address: Address;

  constructor(args: any) {
    const { full_address, suburb, city, geolocation, place_id } =
      args.address || {};

    this.id = args.id;
    this.name = args.name;
    this.address = new Address(
      full_address,
      suburb,
      city,
      geolocation,
      place_id
    );
  }
}
