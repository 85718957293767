// @ts-check
import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";

import Divider from "@material-ui/core/Divider";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import GroupIcon from "@material-ui/icons/Group";

import PinDropIcon from "@material-ui/icons/PinDrop";
import { LoadingComponent } from "../../../guardians/presentation/components/loading-component";

import BusinessIcon from "@material-ui/icons/Business";

import TimelineIcon from "@material-ui/icons/Timeline";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import OverviewPage from "./overview-page";
import SettingsIcon from "@material-ui/icons/Settings";
import PlaceIcon from "@material-ui/icons/Place";
const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0),
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  sectionHeading: {
    margin: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    cursor: "pointer",
  },
  container: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  clientLogo: {
    height: "8rem",
    width: "8rem",
    objectFit: "contain",
  },
  icon: {
    fontSize: "3em",
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  heading: {
    margin: theme.spacing(2),
  },
}));

function Menu() {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Paper
          className={classes.paper}
          elevation={0}
          onClick={() => history.push("/organization")}
        >
          <BusinessIcon className={classes.icon} fontSize="small" />

          <div className="uk-margin-small">
            <span>Organization</span>
          </div>
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper
          className={classes.paper}
          elevation={0}
          onClick={() => history.push("/guardians")}
        >
          <LocalHospitalIcon className={classes.icon} fontSize="small" />

          <div className="uk-margin-small">
            <span>Health Pro's</span>
          </div>
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper
          className={classes.paper}
          elevation={0}
          onClick={() => history.push("/users")}
        >
          <GroupIcon className={classes.icon} fontSize="small" />

          <div className="uk-margin-small">
            <span>Users</span>
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
}

function Plan() {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Paper
          className={classes.paper}
          elevation={0}
          onClick={() => history.push("/locations")}
        >
          <PlaceIcon className={classes.icon} fontSize="small" />

          <div className="uk-margin-small">
            <span>Locations</span>
          </div>
        </Paper>
      </Grid>

      <Grid item xs={4}>
        <Paper
          className={classes.paper}
          elevation={0}
          onClick={() => history.push("/billing")}
        >
          <AccountBalanceWalletIcon className={classes.icon} fontSize="small" />

          <div className="uk-margin-small">
            <span>Billing</span>
          </div>
        </Paper>
      </Grid>

      <Grid item xs={4}>
        <Paper
          className={classes.paper}
          elevation={0}
          onClick={() => history.push("/account")}
        >
          <SettingsIcon className={classes.icon} fontSize="small" />

          <div className="uk-margin-small">
            <span>Settings</span>
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default function HomePage() {
  const classes = useStyles();

  const { loading } = useSelector((state) => state.guardians);

  return (
    <>
      <React.Fragment>
        <CssBaseline />
        <Container className={classes.container}>
          {loading && <LoadingComponent />}
          {!loading && (
            <>
              <Menu />
              <br />
              <Plan />

              <OverviewPage />
            </>
          )}

          <></>
        </Container>
      </React.Fragment>
    </>
  );
}
