import { Organization } from "../entities/organization-entity";

import { OrganizationRepository } from "../repositories/organization-repository";

interface OrganizationUsecase extends OrganizationRepository {}

export class OrganizationUsecaseImpl implements OrganizationUsecase {
  organizationRepository: OrganizationRepository;

  constructor(cr: OrganizationRepository) {
    this.organizationRepository = cr;
  }

  GetOrganizations(): Promise<Organization[]> {
    return this.organizationRepository.GetOrganizations();
  }

  GetOrganization(): Promise<Organization> {
    return this.organizationRepository.GetOrganization();
  }

  UploadLogo(organization_id: string, file: any): Promise<Organization> {
    return this.organizationRepository.UploadLogo(organization_id, file);
  }
}
