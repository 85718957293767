import {
  LOCATION_LOAD_REQUEST,
  LOCATION_LOAD_SUCCESS,
  LOCATION_LOAD_FAILURE,
} from "../types/location-types";

const initialState = {
  loading: true,
  success: false,
  error: null,
  location: null,
};

function location(state = initialState, action: any = null) {
  switch (action.type) {
    case LOCATION_LOAD_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };

    case LOCATION_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
      };

    case LOCATION_LOAD_SUCCESS:
      return {
        ...state,
        location: action.payload,
        loading: false,
        success: true,
        error: null,
      };

    default:
      return state;
  }
}

export default location;
