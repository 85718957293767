import { Service } from "typedi";
import { Account } from "../entities/account-entity";
import { Transaction } from "../entities/transactions-entity";
import { BillingRepository } from "../repositories/billing-repository";

export interface BillingUsecase {
  GetAccount(): Promise<Account>;
  GetTransactions(): Promise<Transaction[]>;
  UpdatePayoutBankDetails(
    bank_name: string,
    account_no: string,
    branch_code: string,
    account_holder: string
  ): Promise<Account>;
}

@Service()
export class BillingUsecaseImpl implements BillingUsecase {
  private billingRepository: BillingRepository;

  constructor(billingRepository: BillingRepository) {
    this.billingRepository = billingRepository;
  }

  GetAccount(): Promise<Account> {
    return this.billingRepository.GetAccount();
  }

  GetTransactions(): Promise<Transaction[]> {
    return this.billingRepository.GetTransactions();
  }

  UpdatePayoutBankDetails(
    bank_name: string,
    account_no: string,
    branch_code: string,
    account_holder: string
  ): Promise<Account> {
    return this.billingRepository.UpdatePayoutBankDetails(
      bank_name,
      account_no,
      branch_code,
      account_holder
    );
  }
}
