// @ts-check
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import CssBaseline from "@material-ui/core/CssBaseline";

import { getAnalyticsAction } from "../redux/actions/analytics-actions";
import { LoadingComponent } from "../../../guardians/presentation/components/loading-component";
import { WeeklyTraffic } from "./weekly-traffic-page";

export default function AnalyticsPage({
  level = "organization",
  id,
  location_id = undefined,
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    const filter = {
      guardian: {
        id: id,
      },
      organization: {
        id: id,
      },
      location: {
        id: location_id,
      },
    };
    dispatch(getAnalyticsAction(level, filter));
  }, [dispatch, level, id, location_id]);

  const { analytics, loading, error } = useSelector((state) => state.analytics);

  if (error) return <div>Error! {error.message}</div>;

  return (
    <>
      <React.Fragment>
        <CssBaseline />

        {loading && <LoadingComponent />}
        {!loading && analytics && (
          <>
            <WeeklyTraffic
              bookings={analytics.bookings}
              reasons={analytics.reasons}
            />
          </>
        )}
      </React.Fragment>
    </>
  );
}
