// @ts-check
import React from "react";

import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";

import { MenuItem, TextField } from "@material-ui/core";
import { inviteUserAction } from "../redux/actions/user-actions";
import FormControl from "@material-ui/core/FormControl";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { IconButton, ListItemSecondaryAction } from "@material-ui/core";
import LocationOnIcon from "@material-ui/icons/LocationOn";

import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { LocationAddressComponent } from "../../../../core/utils/location-address-component";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  sectionHeading: {
    margin: theme.spacing(2),
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  formButtons: {
    marginTop: theme.spacing(0),
  },
  errorText: {
    color: "#f44336",
    textAlign: "center",
    display: "block",
    margin: theme.spacing(3),
  },
  paperPlain: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    cursor: "pointer",
  },
}));

function InviteUserForm(props) {
  const classes = useStyles();

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
    props;

  const { locations } = useSelector((state) => state.locations);

  const locationsList = locations.map((location) => ({
    value: location.id,
    label: location.name,
  }));

  /**
   * @param {string} name
   */
  const formikProps = (name, initialValue = "") => ({
    name: name,
    value: typeof values[name] !== "undefined" ? values[name] : initialValue,
    onChange: handleChange,
    onBlur: handleBlur,
    error: touched[name] && Boolean(errors[name]),
    helperText: touched[name] ? errors[name] : "",
  });

  const location = values.location
    ? locations.filter((x) => x.id === values.location)[0]
    : null;

  return (
    <form
      className={classes.root}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <FormControl required component="fieldset" fullWidth>
        <FormGroup>
          <FormControlLabel
            {...formikProps("administrator")}
            control={<Checkbox name="administrator" />}
            label="Administrator"
          />
        </FormGroup>
      </FormControl>

      <TextField
        {...formikProps("location")}
        id="outlined-select-currency"
        select
        label="Location"
        variant="outlined"
        fullWidth={true}
      >
        {locationsList.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      {values.location && (
        <>
          <div className="uk-margin">
            <List className={classes.paperPlain}>
              <ListItem>
                <ListItemText
                  primary={`${location.name}`}
                  secondary={null}
                ></ListItemText>

                <ListItemSecondaryAction>
                  <IconButton edge="end">
                    <LocationOnIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </div>

          <LocationAddressComponent location={location} />
        </>
      )}

      <div className={classes.formButtons}>
        <>
          <div className="uk-margin  uk-margin-top">
            <button
              className="uk-button uk-button-primary uk-width-1-1"
              type="submit"
            >
              Invite User
            </button>
          </div>
        </>
      </div>
    </form>
  );
}

const validationSchema = Yup.object({
  administrator: Yup.boolean().required(),
  location: Yup.string().required("Location is required"),
});

export function InviteUserFormComponent({
  user,
  resetSearch,
  openBackdrop,
  closeBackdrop,
  showSnackbar,
}) {
  const history = useHistory();
  const dispatch = useDispatch();

  const values = {
    administrator: false,
    location: "",
  };

  const submit = async (data, actions) => {
    const { location, administrator } = data;
    const permissions = [
      {
        location_id: location,
        roles: ["frontdesk"],
      },
    ];

    openBackdrop();

    const props = { history, resetSearch, closeBackdrop, showSnackbar };
    dispatch(inviteUserAction(user.id, administrator, permissions, props));
  };

  return (
    <div className="uk-margin">
      <Formik
        initialValues={values}
        validationSchema={validationSchema}
        onSubmit={submit}
      >
        {(props) => <InviteUserForm {...props} />}
      </Formik>
    </div>
  );
}
