// @ts-check
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import { useHistory } from "react-router-dom";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import IconButton from "@material-ui/core/IconButton";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
    marginBottom: theme.spacing(2),
  },
  inline: {
    display: "inline",
  },
  indicator: {},
}));

export function LocationsListComponent({ locations }) {
  const classes = useStyles();
  const history = useHistory();

  const n = locations.length - 1;
  const renderUsers = locations.map((location, index) => {
    return (
      <List className={classes.root}>
        <div key={index}>
          <ListItem
            alignItems="flex-start"
            button
            onClick={() => history.push(`/locations/${location.id}/view`)}
          >
            <ListItemText
              primary={location.name}
              secondary={
                <React.Fragment>{location.address.full_address}</React.Fragment>
              }
            />

            {/* <ListItemSecondaryAction>{role}</ListItemSecondaryAction> */}
            <ListItemSecondaryAction>
              <IconButton edge="end">
                <LocationOnIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </div>
      </List>
    );
  });

  return <div>{renderUsers}</div>;
}
