import { Dispatch } from "redux";
import { OrganizationRepositoryImpl } from "../../../data/repositories/organization-repository-impl";
import { OrganizationUsecaseImpl } from "../../../domain/usecases/organization-usecase";
import {
  ORGANIZATION_LOAD_REQUEST,
  ORGANIZATION_LOAD_SUCCESS,
  ORGANIZATION_LOAD_FAILURE,
} from "../types/organization-types";

import {
  UPLOAD_FAILURE,
  UPLOAD_REQUEST,
  UPLOAD_SUCCESS,
} from "../types/upload-types";

export const getOrganizationAction = () => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: ORGANIZATION_LOAD_REQUEST });

    try {
      const clientRepository = new OrganizationRepositoryImpl();
      const OrganizationUsecase = new OrganizationUsecaseImpl(clientRepository);

      const result = await OrganizationUsecase.GetOrganization();

      dispatch({ type: ORGANIZATION_LOAD_SUCCESS, payload: result });
    } catch (error) {
      dispatch({ type: ORGANIZATION_LOAD_FAILURE, error });
    }
  };
};

export const uploadLogoAction = (
  organization_id: string,
  file: any,
  props: any
) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: UPLOAD_REQUEST });

    try {
      const clientRepository = new OrganizationRepositoryImpl();
      const OrganizationUsecase = new OrganizationUsecaseImpl(clientRepository);

      const result = await OrganizationUsecase.UploadLogo(
        organization_id,
        file
      );

      dispatch({ type: ORGANIZATION_LOAD_SUCCESS, payload: result });
      dispatch({ type: UPLOAD_SUCCESS });
      props.uploadSuccess();
    } catch (error) {
      dispatch({ type: UPLOAD_FAILURE, error });
    }
  };
};
