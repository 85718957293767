interface ITransaction {
  id: string;
  account_id: string;
  currency: string;
  amount: number;
  type: string;
  task: string;
  date: Date;
  metadata?: object;
  created_at: Date;
  description: string;
}

export class Transaction implements ITransaction {
  id: string;
  account_id: string;
  currency: string;
  amount: number;
  type: string;
  task: string;
  date: Date;
  metadata?: object;
  created_at: Date;
  description: string;

  constructor(
    id: string,
    account_id: string,
    currency: string,
    amount: number,
    type: string,
    task: string,
    date: Date,
    metadata: object,
    created_at: Date,
    description: string
  ) {
    this.id = id;
    this.account_id = account_id;
    this.currency = currency;
    this.amount = amount;
    this.type = type;
    this.task = task;
    this.date = new Date(date);
    this.metadata = metadata;
    this.created_at = new Date(created_at);
    this.description = description;
  }
}
