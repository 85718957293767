// @ts-check
import React, { useState } from "react";
import { useSelector } from "react-redux";

import CssBaseline from "@material-ui/core/CssBaseline";

import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import { IconButton, ListItemSecondaryAction } from "@material-ui/core";
import LocationOnIcon from "@material-ui/icons/LocationOn";

import AnalyticsPage from "../../../analytics/presentation/pages/analytics-page";
import { BillingAnalytics } from "../../../billing/presentation/pages/transactions-page";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  heading: {
    margin: theme.spacing(2),
  },
  paperPlain: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    cursor: "pointer",
  },
}));

export default function OverviewPage() {
  const classes = useStyles();

  const { me } = useSelector((state) => state.me);

  const [view, setView] = useState("analytics");

  return (
    <>
      <React.Fragment>
        <CssBaseline />
        <>
          <Grid container spacing={2} justify="center">
            <Grid item xs={12} md={12}>
              {/* <LocationAddressComponent location={location} /> */}
            </Grid>
            <Grid item xs={12} md={8}>
              <>
                <div>
                  <ul className="uk-child-width-expand" data-uk-tab>
                    <li className={(view === `analytics` && "uk-active") || ""}>
                      {/* eslint-disable-next-line */}
                      <a onClick={() => setView("analytics")}>
                        <small className="uk-display-block uk-padding-small">
                          <b>Overview</b>
                        </small>
                      </a>
                    </li>

                    <li className={(view === `guardians` && "uk-active") || ""}>
                      {/* eslint-disable-next-line */}
                      <a onClick={() => setView("guardians")}>
                        <small className="uk-display-block uk-padding-small">
                          <b>Health Pro's</b>
                        </small>
                      </a>
                    </li>
                  </ul>
                </div>
              </>

              {view === "analytics" && <BillingAnalytics />}

              {/* {view === "analytics" && (
                <div className="uk-margin">
                  <AnalyticsPage level="organization" id={me.organization.id} />
                </div>
              )} */}
            </Grid>
          </Grid>
        </>
      </React.Fragment>
    </>
  );
}
