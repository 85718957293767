import { User } from "../../domain/entities/user-entity";
import { IUserRepository } from "../../domain/repositories/iuser-repository";
import { findUsers } from "../datasources/find-users";
import { getUser } from "../datasources/get-user";
import { getUsers } from "../datasources/get-users";
import { inviteUser } from "../datasources/invite-user";
import { removeUser } from "../datasources/remove-user";
import { updateUserPermission } from "../datasources/update-user-permission";

export class UserRepository implements IUserRepository {
  async FindUsers(keyword: string): Promise<User[]> {
    const result = await findUsers(keyword);

    return result.map((user: any) => new User(user));
  }

  async GetUsers(location_id?: string): Promise<User[]> {
    const result = await getUsers(location_id);

    return result.map((user: any) => new User(user));
  }

  async GetUser(organization_user_id: string): Promise<User> {
    const result = await getUser(organization_user_id);

    return new User(result);
  }

  async InviteUser(
    user_id: string,
    administrator: boolean,
    permissions: any[]
  ): Promise<User[]> {
    const result = await inviteUser(user_id, administrator, permissions);

    return result.map((user: any) => new User(user));
  }

  async RemoveUser(organization_user_id: string): Promise<boolean> {
    const result = await removeUser(organization_user_id);

    return result.removed;
  }

  async UpdateUserPermissions(
    organization_user_id: string,
    level: string,
    type: string,
    permission: any
  ): Promise<User> {
    const result = await updateUserPermission(
      organization_user_id,
      level,
      type,
      permission
    );

    return new User(result);
  }
}
