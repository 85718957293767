// @ts-check
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";

import { BackNavigationComponent } from "../../../../core/routes/components/back-navigation-component";

import { LoadingComponent } from "../components/loading-component";
import Grid from "@material-ui/core/Grid";

import { getLocationsAction } from "../redux/actions/location-actions";
import { LocationsListComponent } from "../components/locations-list-component";
import Divider from "@material-ui/core/Divider";
import { LocationsMapComponent } from "../components/locations-map-component";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  heading: {
    margin: theme.spacing(2),
  },
}));

export default function LocationsPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getLocationsAction());
  }, [dispatch]);

  const { locations, loading, success, error } = useSelector(
    (state) => state.locations
  );

  if (error) return <div>Error! {error.message}</div>;

  return (
    <>
      <React.Fragment>
        <CssBaseline />
        <Container className={classes.container}>
          {(loading || !success) && <LoadingComponent />}
          {!loading && success && (
            <>
              <BackNavigationComponent
                page="Locations"
                path={[
                  {
                    page: "Home",
                    uri: "/",
                  },
                ]}
              />

              <Grid container spacing={2} justify="center">
                <Grid item xs={12} md={12}>
                  {/* <TextBackgroundComponent text={"Locations"} /> */}
                  <LocationsMapComponent locations={locations} />
                </Grid>
                <Grid item xs={12} md={8}>
                  <div className="uk-margin">
                    <button
                      className={`uk-button uk-button-primary uk-width-1-1`}
                      style={{
                        background: "unset",
                        color: "#012535",
                        borderColor: "#012535",
                      }}
                      onClick={() => history.push("/locations/create")}
                    >
                      Create New Location
                    </button>
                  </div>
                  <Divider />
                  <div className="uk-margin">
                    <LocationsListComponent locations={locations} />
                  </div>
                </Grid>
              </Grid>
            </>
          )}
        </Container>
      </React.Fragment>
    </>
  );
}
