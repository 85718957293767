// @ts-check
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import DI from "typedi";
import { GuardianActions } from "../redux/actions/guardian-actions";
const guardianActions = DI.get(GuardianActions);

export function CancelGuardianInvitationComponent({
  guardian_id,
  openBackdrop,
  closeBackdrop,
  showSnackbar,
}) {
  const history = useHistory();
  const dispatch = useDispatch();

  const action = () => {
    openBackdrop();

    const props = { history, closeBackdrop, showSnackbar };
    dispatch(
      guardianActions.cancelGuardianInviteOrganizationAction(guardian_id, props)
    );
  };

  return (
    <div className="uk-margin">
      <button
        className={`uk-button uk-button-danger uk-width-1-1`}
        onClick={action}
      >
        Cancel Invite
      </button>
    </div>
  );
}
