// @ts-check
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { IconButton, ListItemSecondaryAction } from "@material-ui/core";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
}));

export function AccountBalanceComponent({ billing }) {
  const classes = useStyles();

  let color = "inherit";
  if (billing) {
    color = billing.balance < 0 ? "#f44336" : "#4caf50";
  }

  return (
    <List className={classes.root}>
      <ListItem>
        <ListItemIcon>
          <AccountBalanceWalletIcon />
        </ListItemIcon>

        <ListItemText
          primary={<small>Account Balance</small>}
          secondary={
            <h5 className="uk-margin-remove">
              {billing
                ? `${billing.currency} ${billing.balance.toFixed(2)}`
                : `--- -.--`}
            </h5>
          }
        ></ListItemText>

        <ListItemSecondaryAction>
          <IconButton edge="end" aria-label="vie key">
            <FiberManualRecordIcon
              style={{
                color: color,
              }}
            />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  );
}
