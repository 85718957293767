import fetch from "isomorphic-fetch";
import config from "../../../../core/config/config";
import store from "../../../../core/redux/store";

export const uploadLogo = async (
  organization_id: string,
  file: any
): Promise<any> => {
  try {
    const state = store.getState();

    const body = new FormData();
    body.append("file", file);
    body.append("organization_id", organization_id);

    const uri = `${config.uri.upload_url}/upload-organization-logo`;

    const options = {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${state.token.token}`,
      }),
      body: body,
    };

    const response = await fetch(uri, options);
    const result = await response.json();

    if (!response.ok) {
      throw new Error(result.message || "Something went wrong");
    }

    return result;
  } catch (err) {
    throw err;
  }
};
