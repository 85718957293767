// @ts-check
import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

import { BackdropComponent } from "../../../../core/utils/backdrop-component";
import { SearchGlobalGuardiansComponent } from "../components/search-global-guardians-component";
import { SnackbarComponent } from "../../../../core/utils/snackbar-component";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    alignItems: "center",
    marginTop: theme.spacing(2),
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    cursor: "pointer",
  },
}));

export function GuardiansInviteComponent({ organization }) {
  const classes = useStyles();

  const [backdrop, setBackdrop] = useState(false);

  const openBackdrop = () => {
    setBackdrop(true);
  };

  const closeBackdrop = () => {
    setBackdrop(false);
  };

  const [snackbar, setSnackbar] = useState(false);
  const [message, setMessage] = useState({
    status: "success",
    description: "N/A",
  });

  const showSnackbar = (status, description) => {
    setSnackbar(true);
    setMessage({
      status,
      description,
    });
  };

  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbar(false);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={0}>
        <span>Invite a Health Professional to {organization.name}</span>
      </Paper>
      <br />

      <SearchGlobalGuardiansComponent
        openBackdrop={openBackdrop}
        closeBackdrop={closeBackdrop}
        showSnackbar={showSnackbar}
      />

      <BackdropComponent open={backdrop} />
      <SnackbarComponent
        open={snackbar}
        onClose={closeSnackbar}
        severity={message.status}
        message={message.description}
      />
    </div>
  );
}
