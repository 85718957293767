// @ts-check
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import { ListItemSecondaryAction } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
  indicator: {},
  right: {
    textAlign: "right",
  },
}));

export function TransactionsListComponent({ transactions }) {
  const classes = useStyles();

  const n = transactions.length - 1;
  const renderUsers = transactions.map((transaction, index) => {
    let color = "inherit";
    color = transaction.type === "debit" ? "#f44336" : "#4caf50";

    const month = transaction.date.toLocaleString("default", {
      month: "short",
    });

    return (
      <div key={index}>
        <ListItem alignItems="flex-start">
          <ListItemText
            primary={<small>{`${transaction.date.getDate()} ${month}`}</small>}
            secondary={
              <React.Fragment>{transaction.description}</React.Fragment>
            }
          />

          <ListItemSecondaryAction>
            <span
              //   className="uk-label uk-border-rounded "
              style={{
                color: color,
              }}
            >
              {`${transaction.currency} ${transaction.amount.toFixed(2)}`}
            </span>
          </ListItemSecondaryAction>
        </ListItem>
        {index !== n && <Divider component="li" />}
      </div>
    );
  });

  return <List className={classes.root}>{renderUsers}</List>;
}
