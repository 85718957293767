// @ts-check
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import CssBaseline from "@material-ui/core/CssBaseline";

import { GuardianListComponent } from "../components/guardians-list-component";
import { LoadingComponent } from "../components/loading-component";

import DI from "typedi";
import { GuardianActions } from "../redux/actions/guardian-actions";
const guardianActions = DI.get(GuardianActions);

export default function GuardiansLocationPage({ location_id }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(guardianActions.getGuardiansAction(location_id));
  }, [dispatch, location_id]);

  const { guardians, loading, error } = useSelector((state) => state.guardians);

  if (error) return <div>Error! {error.message}</div>;

  return (
    <>
      <React.Fragment>
        <CssBaseline />

        {loading && <LoadingComponent />}
        {!loading && (
          <>
            <GuardianListComponent guardians={guardians} />
          </>
        )}
      </React.Fragment>
    </>
  );
}
