import { LocationEntity } from "../../domain/entities/location-entity";
import { ILocationRepository } from "../../domain/repositories/ilocation-repository";
import { createLocation } from "../datasources/create-location";
import { getLocation } from "../datasources/get-location";
import { getLocations } from "../datasources/get-locations";

export class LocationRepository implements ILocationRepository {
  async CreateLocation(name: string, address: any): Promise<LocationEntity> {
    const result = await createLocation(name, address);

    return new LocationEntity(result);
  }

  EditLocation(
    location_id: string,
    name: string,
    address: any
  ): Promise<LocationEntity> {
    throw new Error("Method not implemented.");
  }

  async GetLocations(): Promise<LocationEntity[]> {
    const results = await getLocations();

    return results.map((location: any) => new LocationEntity(location));
  }

  async GetLocation(location_id: string): Promise<LocationEntity> {
    const result = await getLocation(location_id);

    return new LocationEntity(result);
  }
}
