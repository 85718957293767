import { Organization } from "../../domain/entities/organization-entity";
import { SuperUser } from "../../domain/entities/super-user-entity";
import { OrganizationRepository } from "../../domain/repositories/organization-repository";

import { findUsers } from "../datasources/find-users";

import { getClient } from "../datasources/get-organization";
import { getClients } from "../datasources/get-organizations";
import { inviteUser } from "../datasources/invite-user";
import { uploadLogo } from "../datasources/upload-logo";

export class OrganizationRepositoryImpl implements OrganizationRepository {
  async GetOrganizations(): Promise<Organization[]> {
    const organizations = await getClients();

    return organizations.map((client: any) => new Organization(client));
  }

  async GetOrganization(): Promise<Organization> {
    const result = await getClient();

    return new Organization(result, result.users);
  }

  async UploadLogo(organization_id: string, file: any): Promise<Organization> {
    await uploadLogo(organization_id, file);
    const result = await getClient();

    return new Organization(result);
  }
}
