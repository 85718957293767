import { IGuardianRepository } from "../../domain/repositories/guardian-repository";
import { Guardian } from "../../domain/entities/guardian-entity";

import { Api } from "../../../../core/api/api";
import config from "../../../../core/config/config";
import { Service } from "typedi";

@Service()
export class GuardianRepository implements IGuardianRepository {
  private readonly api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async GetOrganizationGuardians(location_id?: string): Promise<Guardian[]> {
    let uri = `${config.uri.guardians_url}/guardians/organization`;

    if (location_id) {
      uri = `${config.uri.guardians_url}/guardians/organization?location_id=${location_id}`;
    }

    const result = await this.api.Get(uri);

    return result.map((guardian) => new Guardian(guardian));
  }

  async GetOrganizationGuardian(guardian_id: string): Promise<Guardian> {
    const uri = `${config.uri.guardians_url}/guardians/${guardian_id}/organization`;
    const result = await this.api.Get(uri);

    return new Guardian(result);
  }

  async SearchGuardians(keyword: string): Promise<Guardian[]> {
    const uri = `${config.uri.guardians_url}/guardians/organization/search?keyword=${keyword}`;
    const result = await this.api.Get(uri);

    return result.map((guardian) => new Guardian(guardian));
  }

  async InviteGuardianToOrganization(
    guardian_id: string,
    location_id: string
  ): Promise<Guardian[]> {
    const uri = `${config.uri.guardians_url}/guardians/organization/invite`;
    const body = {
      guardian_id,
      location_id,
    };
    const result = await this.api.Post(uri, body);

    return result.map((guardian) => new Guardian(guardian));
  }

  async RemoveGuardianFromOrganization(guardian_id: string): Promise<Guardian> {
    const uri = `${config.uri.guardians_url}/guardians/organization/remove`;
    const body = {
      guardian_id,
    };
    const result = await this.api.Post(uri, body);

    return new Guardian(result);
  }

  async CancelGuardianInvitation(guardian_id: string): Promise<Guardian> {
    const uri = `${config.uri.guardians_url}/guardians/organization/cancel/invite`;
    const body = {
      guardian_id,
    };
    const result = await this.api.Post(uri, body);

    return new Guardian(result);
  }
}
