import {
  ORGANIZATION_LOAD_REQUEST,
  ORGANIZATION_LOAD_SUCCESS,
  ORGANIZATION_LOAD_FAILURE
} from '../types/organization-types';

const initialState = {
  loading: false,
  success: false,
  error: null,
  organization: null
};

function organization(state = initialState, action: any = null) {
  switch (action.type) {
    case ORGANIZATION_LOAD_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        error: null
      };

    case ORGANIZATION_LOAD_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
        success: false
      };

    case ORGANIZATION_LOAD_SUCCESS:
      return {
        ...state,
        organization: action.payload,
        loading: false,
        success: true,
        error: null
      };

    default:
      return state;
  }
}

export default organization;
