// @ts-check
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";

import { BackNavigationComponent } from "../../../../core/routes/components/back-navigation-component";
import LocalHospitalOutlinedIcon from "@material-ui/icons/LocalHospitalOutlined";
import DateRangeIcon from "@material-ui/icons/DateRange";
import GroupIcon from "@material-ui/icons/Group";

import { LoadingComponent } from "../components/loading-component";
import Grid from "@material-ui/core/Grid";

import { TextBackgroundComponent } from "../components/text-background-component";
import { getLocationAction } from "../redux/actions/location-actions";
import GuardiansLocationPage from "../../../guardians/presentation/pages/guardians-location-page";
import { WorkAddressComponent } from "../components/work-address-component";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import UsersLocationPage from "../../../users/presentation/pages/users-location-page";
import AnalyticsPage from "../../../analytics/presentation/pages/analytics-page";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  heading: {
    margin: theme.spacing(2),
  },
}));

function LocationComponent({ location }) {
  const classes = useStyles();
  const { me } = useSelector((state) => state.me);

  const [view, setView] = useState("details");

  return (
    <Grid container spacing={2} justify="center">
      <Grid item xs={12} md={12}>
        <TextBackgroundComponent text={location.name} />
      </Grid>

      <Grid item xs={12} md={8}>
        <>
          <div>
            <ul className="uk-child-width-expand" data-uk-tab>
              <li className={(view === `details` && "uk-active") || ""}>
                {/* eslint-disable-next-line */}
                <a onClick={() => setView("details")}>
                  <small className="uk-display-block uk-padding-small">
                    <span className="uk-margin-small-right">
                      <DateRangeIcon fontSize={"small"} />
                    </span>
                    <b>Details</b>
                  </small>
                </a>
              </li>

              <li className={(view === `guardians` && "uk-active") || ""}>
                {/* eslint-disable-next-line */}
                <a onClick={() => setView("guardians")}>
                  <small className="uk-display-block uk-padding-small">
                    <span className="uk-margin-small-right">
                      <LocalHospitalOutlinedIcon fontSize={"small"} />
                    </span>

                    <b>Guardians</b>
                  </small>
                </a>
              </li>

              <li className={(view === `users` && "uk-active") || ""}>
                {/* eslint-disable-next-line */}
                <a onClick={() => setView("users")}>
                  <small className="uk-display-block uk-padding-small">
                    <span className="uk-margin-small-right">
                      <GroupIcon fontSize={"small"} />
                    </span>

                    <b>Users</b>
                  </small>
                </a>
              </li>
            </ul>
          </div>
        </>

        {view === "details" && (
          <>
            <div className="uk-margin">
              <List className={classes.root}>
                <ListItem>
                  <ListItemText
                    primary={`${location.name}`}
                    secondary={null}
                  ></ListItemText>

                  <ListItemSecondaryAction>
                    <IconButton edge="end">
                      <LocationOnIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </div>

            <WorkAddressComponent location={location} />

            <div className="uk-margin">
              <AnalyticsPage
                level="organization"
                id={me.organization.id}
                location_id={location.id}
              />
            </div>
          </>
        )}

        {view === "guardians" && (
          <GuardiansLocationPage location_id={location.id} />
        )}

        {view === "users" && <UsersLocationPage location_id={location.id} />}
      </Grid>
    </Grid>
  );
}

export default function LocationPage() {
  // @ts-ignore
  const { id } = useParams();

  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLocationAction(id));
  }, [dispatch, id]);

  const { location, loading, success, error } = useSelector(
    (state) => state.location
  );

  if (error) return <div>Error! {error.message}</div>;

  return (
    <>
      <React.Fragment>
        <CssBaseline />
        <Container className={classes.container}>
          {(loading || !success) && <LoadingComponent />}
          {!loading && success && (
            <>
              <BackNavigationComponent
                page="Location"
                path={[
                  {
                    page: "Home",
                    uri: "/",
                  },
                  {
                    page: "Locations",
                    uri: "/locations",
                  },
                ]}
              />

              <LocationComponent location={location} />
            </>
          )}
        </Container>
      </React.Fragment>
    </>
  );
}
