// @ts-check
import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";

import { BackNavigationComponent } from "../../../../core/routes/components/back-navigation-component";
import { TextBackgroundComponent } from "../components/text-background-component";

import { CreateLocationFormComponent } from "../components/create-location-form";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  heading: {
    margin: theme.spacing(2),
  },
}));

export default function CreateLocationPage() {
  const classes = useStyles();

  return (
    <>
      <React.Fragment>
        <CssBaseline />
        <Container className={classes.container}>
          <>
            <BackNavigationComponent
              page="Create"
              uri="/cancel"
              path={[
                {
                  page: "Home",
                  uri: "/",
                },
                {
                  page: "Locations",
                  uri: "/locations",
                },
              ]}
            />

            <Grid container spacing={2} justify="center">
              <Grid item xs={12} md={12}>
                <TextBackgroundComponent text={"Create Location"} />
              </Grid>
              <Grid item xs={12} md={8}>
                <CreateLocationFormComponent />
              </Grid>
            </Grid>
          </>
        </Container>
      </React.Fragment>
    </>
  );
}
