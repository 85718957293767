import { UPLOAD_FAILURE, UPLOAD_REQUEST, UPLOAD_SUCCESS } from '../types/upload-types';

const initialState = {
  loading: false,
  error: null
};

function upload(state = initialState, action: any = null) {
  switch (action.type) {
    case UPLOAD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };

    case UPLOAD_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };

    default:
      return state;
  }
}

export default upload;
