// @ts-check
import React from "react";

import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { PayoutDetailsFormComponent } from "./payout-details-form-component";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
  heading: {
    margin: theme.spacing(2),
  },
  fab: {
    width: "100%",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  center: {
    textAlign: "center",
  },
  right: {
    textAlign: "right",
  },
}));

export function PayoutDetailsComponent({ payout }) {
  const classes = useStyles();

  const history = useHistory();

  return (
    <div className="uk-margin">
      <div className="uk-margin-small uk-text-center">
        <div className="uk-margin- uk-card uk-card-default  uk-padding-small uk-border-rounded uk-text-center">
          <small>Payouts are done weekly on a Monday</small>
        </div>
      </div>

      {payout && (
        <List className={classes.root}>
          <ListItem>
            <ListItemText
              primary={<small>Bank</small>}
              secondary={`${payout.bank_name}`}
            />

            <ListItemText
              className={classes.center}
              primary={<small>Account no.</small>}
              secondary={`${payout.account_no}`}
            />

            <ListItemText
              className={classes.right}
              primary={<small>Branch code</small>}
              secondary={`${payout.branch_code}`}
            ></ListItemText>
          </ListItem>
          <hr />
          <ListItem>
            <ListItemText
              primary={<small>Account Holder.</small>}
              secondary={`${payout.account_holder}`}
            />

            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="vie key">
                <AccountBoxIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      )}

      <div className="uk-margin">
        <button
          className="uk-button uk-button-primary uk-button-medium uk-width-1-1"
          type="button"
          onClick={() => history.push("/billing/edit/payout/details")}
          // style={styles.subActiveBtn}
        >
          <small>Update Bank Details For Payouts</small>
        </button>
      </div>
    </div>
  );
}

export function BankPayoutDetailsComponent({ payout }) {
  const classes = useStyles();

  return (
    <div className="uk-margin">
      <div className="uk-margin-small uk-text-center">
        <div className="uk-margin- uk-card uk-card-default  uk-padding-small uk-border-rounded uk-text-center">
          <small>Payouts are done weekly on a Monday</small>
        </div>
      </div>

      {payout && (
        <List className={classes.root}>
          <ListItem>
            <ListItemText
              primary={<small>Bank</small>}
              secondary={`${payout.bank_name}`}
            />

            <ListItemText
              className={classes.center}
              primary={<small>Account no.</small>}
              secondary={`${payout.account_no}`}
            />

            <ListItemText
              className={classes.right}
              primary={<small>Branch code</small>}
              secondary={`${payout.branch_code}`}
            ></ListItemText>
          </ListItem>
        </List>
      )}

      <PayoutDetailsFormComponent setViewForm={null} />
    </div>
  );
}
