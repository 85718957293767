import { Service } from "typedi";
import { Api } from "../../../../core/api/api";
import config from "../../../../core/config/config";
import { Account } from "../../domain/entities/account-entity";
import { Transaction } from "../../domain/entities/transactions-entity";
import { BillingRepository } from "../../domain/repositories/billing-repository";

@Service()
export class BillingRepositoryImpl implements BillingRepository {
  private readonly api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async GetAccount(): Promise<Account> {
    const uri = `${config.uri.billing_url}/accounts/organization`;

    const result = await this.api.Get(uri);

    return new Account(
      result.id,
      result.currency,
      result.balance,
      result.status,
      result.bank_details
    );
  }

  async GetTransactions(): Promise<Transaction[]> {
    const uri = `${config.uri.billing_url}/transactions/organization`;

    const result = await this.api.Get(uri);

    return result.map(
      (transaction: any) =>
        new Transaction(
          transaction.id,
          transaction.account_id,
          transaction.currency,
          transaction.amount,
          transaction.type,
          transaction.task,
          transaction.date,
          transaction.metadata,
          transaction.created_at,
          transaction.description
        )
    );
  }

  async UpdatePayoutBankDetails(
    bank_name: string,
    account_no: string,
    branch_code: string,
    account_holder: string
  ): Promise<Account> {
    const uri = `${config.uri.billing_url}/accounts/organization/edit/payout/details`;

    const body = {
      bank_name,
      account_no,
      branch_code,
      account_holder,
    };

    const result = await this.api.Post(uri, body);

    return new Account(
      result.id,
      result.currency,
      result.balance,
      result.status,
      result.bank_details
    );
  }
}
