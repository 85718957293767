// @ts-check
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import { IconButton, ListItemSecondaryAction } from "@material-ui/core";

import BusinessIcon from "@material-ui/icons/Business";

import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
  avatar: {
    "& > *": {
      objectFit: "contain",
    },
  },
}));

export function ClientComponent({ organization }) {
  const classes = useStyles();

  return (
    <>
      <List className={classes.root}>
        <ListItem>
          <ListItemAvatar>
            <Avatar
              variant="square"
              alt={organization.name}
              src={organization.logo || "/static/images/avatar/1.jpg"}
              className={classes.avatar}
            />
          </ListItemAvatar>
          <ListItemText
            primary={`${
              organization.name
            } (${organization.type.toUpperCase()})`}
            secondary={organization.organization}
          ></ListItemText>

          <ListItemSecondaryAction>
            <IconButton edge="end">
              <BusinessIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </>
  );
}
