// @ts-check
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";

import { BackNavigationComponent } from "../../../../core/routes/components/back-navigation-component";

import { LoadingComponent } from "../components/loading-component";
import Grid from "@material-ui/core/Grid";
import { UserComponent } from "../components/user-component.js.js";
import { TextBackgroundComponent } from "../../../locations/presentation/components/text-background-component";
import { getUserAction } from "../redux/actions/user-actions";
import { SnackbarComponent } from "../../../../core/utils/snackbar-component";
import { BackdropComponent } from "../../../../core/utils/backdrop-component";
import { getLocationsAction } from "../../../locations/presentation/redux/actions/location-actions";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  heading: {
    margin: theme.spacing(2),
  },
}));

export default function UserPage() {
  // @ts-ignore
  const { id } = useParams();

  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserAction(id));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getLocationsAction());
  }, [dispatch]);

  const { user, loading, success, error } = useSelector((state) => state.user);

  // helpers
  const [backdrop, setBackdrop] = useState(false);

  const openBackdrop = () => {
    setBackdrop(true);
  };

  const closeBackdrop = () => {
    setBackdrop(false);
  };

  const [snackbar, setSnackbar] = useState(false);
  const [message, setMessage] = useState({
    status: "success",
    description: "N/A",
  });

  const showSnackbar = (status, description) => {
    setSnackbar(true);
    setMessage({
      status,
      description,
    });
  };

  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbar(false);
  };

  if (error) return <div>Error! {error.message}</div>;

  return (
    <>
      <React.Fragment>
        <CssBaseline />
        <Container className={classes.container}>
          {(loading || !success) && <LoadingComponent />}
          {!loading && success && (
            <>
              <BackNavigationComponent
                page="User"
                path={[
                  {
                    page: "Home",
                    uri: "/",
                  },
                  {
                    page: "Users",
                    uri: "/users",
                  },
                ]}
              />

              <Grid container spacing={2} justify="center">
                <Grid item xs={12} md={12}>
                  <TextBackgroundComponent text={"User"} />
                </Grid>
                <Grid item xs={12} md={8}>
                  <UserComponent
                    user={user}
                    openBackdrop={openBackdrop}
                    closeBackdrop={closeBackdrop}
                    showSnackbar={showSnackbar}
                  />
                </Grid>
              </Grid>

              <BackdropComponent open={backdrop} />
              <SnackbarComponent
                open={snackbar}
                onClose={closeSnackbar}
                severity={message.status}
                message={message.description}
              />
            </>
          )}
        </Container>
      </React.Fragment>
    </>
  );
}
