// @ts-check
import React, { useEffect, useState } from "react";
import di from "typedi";
import { useDispatch, useSelector } from "react-redux";

import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";

import { BackNavigationComponent } from "../../../../core/routes/components/back-navigation-component";

import Grid from "@material-ui/core/Grid";

import { TextBackgroundComponent } from "../../../locations/presentation/components/text-background-component";

import { BillingActions } from "../redux/actions/billing-actions";
import { LoadingComponent } from "../../../users/presentation/components/loading-component";
import { AccountBalanceComponent } from "../components/account-balance-component";
import { TransactionsPage } from "./transactions-page";
import { PayoutDetailsComponent } from "../components/payout-details-component";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  heading: {
    margin: theme.spacing(2),
  },
}));

export default function BillingPage() {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    const billingActions = di.get(BillingActions);

    dispatch(billingActions.getAccountBalanceAction());
  }, [dispatch]);

  const { billing, loading, success, error } = useSelector(
    (state) => state.billing
  );

  const [view, setView] = useState("overview");

  if (error) return <div>Error! {error.message}</div>;

  return (
    <>
      <React.Fragment>
        <CssBaseline />
        <Container className={classes.container}>
          {(loading || !success) && <LoadingComponent />}
          {!loading && success && (
            <>
              <BackNavigationComponent
                page="Billing"
                path={[
                  {
                    page: "Home",
                    uri: "/",
                  },
                ]}
              />

              <Grid container spacing={2} justify="center">
                <Grid item xs={12} md={12}>
                  <TextBackgroundComponent text={"Billing"} />
                </Grid>
                <Grid item xs={12} md={8}>
                  <div>
                    <ul className="uk-child-width-expand" data-uk-tab>
                      <li
                        className={(view === `overview` && "uk-active") || ""}
                      >
                        {/* eslint-disable-next-line */}
                        <a onClick={() => setView("overview")}>
                          <small className="uk-display-block uk-padding-small">
                            <b>Overview</b>
                          </small>
                        </a>
                      </li>

                      <li
                        className={
                          (view === `transactions` && "uk-active") || ""
                        }
                      >
                        {/* eslint-disable-next-line */}
                        <a onClick={() => setView("transactions")}>
                          <small className="uk-display-block uk-padding-small">
                            <b>Transactions</b>
                          </small>
                        </a>
                      </li>

                      <li
                        className={
                          (view === `transactions` && "uk-active") || ""
                        }
                      >
                        {/* eslint-disable-next-line */}
                        <a onClick={() => setView("transactions")}>
                          <small className="uk-display-block uk-padding-small">
                            <b>Details</b>
                          </small>
                        </a>
                      </li>
                    </ul>
                  </div>
                  {view === "overview" && (
                    <>
                      <AccountBalanceComponent billing={billing} />
                      <PayoutDetailsComponent payout={billing.bank_details} />
                    </>
                  )}
                  {view === "transactions" && <TransactionsPage />}
                </Grid>
              </Grid>
            </>
          )}
        </Container>
      </React.Fragment>
    </>
  );
}
