import {
  TRANSACTIONS_LOAD_REQUEST,
  TRANSACTIONS_LOAD_SUCCESS,
  TRANSACTIONS_LOAD_FAILURE
} from '../types/transactions-types';

const initialState = {
  loading: false,
  error: null,
  success: false,
  transactions: []
};

function transactions(state = initialState, action: any = null) {
  switch (action.type) {
    case TRANSACTIONS_LOAD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };

    case TRANSACTIONS_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case TRANSACTIONS_LOAD_SUCCESS:
      return {
        ...state,
        transactions: action.payload,
        success: true,
        loading: false,
        error: null
      };

    default:
      return state;
  }
}

export default transactions;
