import { LocationEntity } from "../entities/location-entity";
import { ILocationRepository } from "../repositories/ilocation-repository";

export interface ILocationUsecase {
  CreateLocation(name: string, address: any): Promise<LocationEntity>;
  EditLocation(
    location_id: string,
    name: string,
    address: any
  ): Promise<LocationEntity>;
  GetLocations(organization_id: string): Promise<LocationEntity[]>;
  GetLocation(location_id: string): Promise<LocationEntity>;
}

export class LocationUsecase implements ILocationUsecase {
  locationRepository: ILocationRepository;

  constructor(lr: ILocationRepository) {
    this.locationRepository = lr;
  }

  CreateLocation(name: string, address: any): Promise<LocationEntity> {
    return this.locationRepository.CreateLocation(name, address);
  }

  EditLocation(
    location_id: string,
    name: string,
    address: any
  ): Promise<LocationEntity> {
    return this.locationRepository.EditLocation(location_id, name, address);
  }

  GetLocations(): Promise<LocationEntity[]> {
    return this.locationRepository.GetLocations();
  }

  GetLocation(location_id: string): Promise<LocationEntity> {
    return this.locationRepository.GetLocation(location_id);
  }
}
