// @ts-check
import React from "react";

import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";

import { TextField } from "@material-ui/core";
import { LoadingButton } from "../../../../core/constants";
import { CreateBranchAddressComponent } from "./create-branch-address-component";
import { createLocationAction } from "../redux/actions/location-actions";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  sectionHeading: {
    margin: theme.spacing(2),
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  formButtons: {
    marginTop: theme.spacing(0),
  },
  errorText: {
    color: "#f44336",
    textAlign: "center",
    display: "block",
    margin: theme.spacing(3),
  },
}));

function BookAppointmentForm(props) {
  const classes = useStyles();

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
  } = props;

  /**
   * @param {string} name
   */
  const formikProps = (name, initialValue = "") => ({
    name: name,
    value: typeof values[name] !== "undefined" ? values[name] : initialValue,
    onChange: handleChange,
    onBlur: handleBlur,
    error: touched[name] && Boolean(errors[name]),
    helperText: touched[name] ? errors[name] : "",
  });

  const formikAddressProps = (name, initialValue = "") => ({
    name: name,
    onChange: handleChange,
    onBlur: handleBlur,
    error: touched[name] && Boolean(errors[name]),
    autoFocus: false,
    helperText: touched[name] ? "Enter a valid address" : "",
  });

  function callbackAddress(address) {
    setFieldValue("address.full_address", address.full_address);
    setFieldValue("address.suburb", address.suburb);
    setFieldValue("address.city", address.city);
    setFieldValue("address.geolocation.lat", address.geolocation.lat);
    setFieldValue("address.geolocation.lng", address.geolocation.lng);
    setFieldValue("address.place_id", address.place_id);
  }

  const { error, loading } = useSelector((state) => state.loading);

  return (
    <form
      className={classes.root}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <TextField
        {...formikProps("name")}
        id="outlined-full-width"
        label="Location Name"
        type="text"
        variant="outlined"
        fullWidth={true}
      />

      <CreateBranchAddressComponent
        submit={callbackAddress}
        formikAddressProps={formikAddressProps}
      />

      <div className={classes.formButtons}>
        {!loading && (
          <>
            <div className="uk-margin  uk-margin-top">
              <button
                className="uk-button uk-button-primary uk-width-1-1"
                type="submit"
              >
                Create
              </button>
            </div>
          </>
        )}

        {loading && (
          <div className="uk-margin  uk-margin-top">
            <LoadingButton />
          </div>
        )}

        {error && (
          <div>
            <span className={classes.errorText}>{error.message}</span>
          </div>
        )}
      </div>
    </form>
  );
}

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  address: Yup.object()
    .shape({
      full_address: Yup.string().required(),
      suburb: Yup.string().required(),
      city: Yup.string().required(),
      geolocation: Yup.object()
        .shape({
          lat: Yup.number().required(),
          lng: Yup.number().required(),
        })
        .required(),
      place_id: Yup.string().required(),
    })
    .required("Address is required"),
});

export function CreateLocationFormComponent() {
  const history = useHistory();
  const dispatch = useDispatch();

  const values = {
    address: null,
    name: "",
  };

  const submit = async (data, actions) => {
    const { name, address } = data;

    const props = { history };
    console.log(data);

    dispatch(createLocationAction(name, address, props));
  };

  return (
    <div className="uk-margin">
      <Formik
        initialValues={values}
        validationSchema={validationSchema}
        onSubmit={submit}
      >
        {(props) => <BookAppointmentForm {...props} />}
      </Formik>
    </div>
  );
}
