// @ts-check
import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import throttle from "lodash/throttle";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";

import CircularProgress from "@material-ui/core/CircularProgress";
import Avatar from "@material-ui/core/Avatar";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import { IconButton, ListItemSecondaryAction } from "@material-ui/core";

import BusinessIcon from "@material-ui/icons/Business";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";

import { InviteUserFormComponent } from "./invite-user-form-component";
import { searchUsersAction } from "../redux/actions/user-actions";

const nappi = [];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  map: {
    marginTop: theme.spacing(2),
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  formButtons: {
    marginTop: theme.spacing(4),
  },
  errorText: {
    color: "#f44336",
    textAlign: "center",
    display: "block",
    margin: theme.spacing(3),
  },
}));

function SelectedUser({ user }) {
  const classes = useStyles();

  return (
    <>
      <List className={classes.root}>
        <ListItem>
          <ListItemAvatar>
            <Avatar
              alt={user.name}
              src={user.image || "/static/images/avatar/1.jpg"}
            />
          </ListItemAvatar>
          <ListItemText
            primary={`${user.name}`}
            secondary={user.email}
          ></ListItemText>

          <ListItemSecondaryAction>
            <IconButton edge="end">
              <BusinessIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </>
  );
}

// @ts-ignore
export const SearchGlobalUsersComponent = ({
  openBackdrop,
  closeBackdrop,
  showSnackbar,
}) => {
  const dispatch = useDispatch();

  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState(null);

  const { users, loading } = useSelector((state) => state.find_users);

  useEffect(() => {
    setOptions(users);
  }, [users]);

  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        dispatch(searchUsersAction(request.input));

        return nappi;
      }, 200),
    [dispatch]
  );

  useEffect(() => {
    // let active = true;

    if (inputValue === "") {
      // @ts-ignore
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {});
  }, [value, inputValue, fetch]);

  const resetSearch = () => {
    setValue(null);
  };

  return (
    <div className="uk-margin">
      <>
        <Autocomplete
          // @ts-ignore
          getOptionLabel={(option) =>
            // @ts-ignore
            typeof option === "string" ? option : option.name
          }
          filterOptions={(x) => x}
          loading={loading}
          options={options}
          autoComplete
          includeInputInList
          filterSelectedOptions
          value={value}
          onChange={(event, newValue) => {
            // @ts-ignore
            setOptions(newValue ? [newValue, ...options] : options);
            // @ts-ignore
            setValue(newValue);
            console.log("newValue", newValue);
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search by full name or email address"
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
          renderOption={(option) => {
            // @ts-ignore
            const matches = match(option.name, inputValue);
            // @ts-ignore
            const parts = parse(
              // @ts-ignore
              option.name,
              matches
            );

            return (
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <Avatar
                    // variant="square"
                    // @ts-ignore
                    alt={option.name}
                    // @ts-ignore
                    src={option.image || "/static/images/avatar/1.jpg"}
                  />
                </Grid>
                <Grid item xs>
                  {parts.map((part, index) => (
                    <span
                      key={index}
                      style={{ fontWeight: part.highlight ? 700 : 400 }}
                    >
                      {part.text}
                    </span>
                  ))}

                  <Typography variant="body2" color="textSecondary">
                    {
                      // @ts-ignore
                      option.email
                    }
                  </Typography>
                </Grid>
              </Grid>
            );
          }}
        />
      </>

      {/* ///////////////////////////////////////////////// */}

      {value && (
        <>
          <hr />

          <div className="uk-margin">
            <SelectedUser user={value} />

            <InviteUserFormComponent
              user={value}
              resetSearch={resetSearch}
              openBackdrop={openBackdrop}
              closeBackdrop={closeBackdrop}
              showSnackbar={showSnackbar}
            />
          </div>
        </>
      )}
    </div>
  );
};
