// @ts-check
import React, { useRef, useEffect, useCallback } from "react";
import { Loader as MapLoader } from "google-maps";
import { MAP_STYLE } from "./map-style";

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

// @ts-ignore
const Map = ({ place_id }) => {
  const mapRef = useRef();
  const infoWindowContentRef = useRef();

  const mapping = useCallback(async () => {
    const options = {
      libraries: ["places", "geometry"],
    };
    const loader = new MapLoader(GOOGLE_MAPS_API_KEY, options);
    const google = await loader.load();

    if (!mapRef.current) {
      return;
    }

    // @ts-ignore
    const map = new google.maps.Map(mapRef.current, {
      center: { lat: -26.2041, lng: 28.0473 },
      zoom: 13,
      scaleControl: false,
      mapTypeControl: false,
      fullscreenControl: false,
      streetViewControl: false,
    });

    //set style
    map.set("styles", MAP_STYLE);

    const marker = new google.maps.Marker({
      map: map,
      anchorPoint: new google.maps.Point(0, -29),
      draggable: false,
      animation: google.maps.Animation.DROP,
    });

    const infowindow = new google.maps.InfoWindow();
    let infoWindowContent = infoWindowContentRef.current;
    infowindow.setContent(infoWindowContent);

    const service = new google.maps.places.PlacesService(map);
    service.getDetails(
      {
        placeId: place_id,
      },
      function (place, status) {
        console.log("place_id", place_id);
        if (status !== google.maps.places.PlacesServiceStatus.OK) return;
        newFunction(place, map, marker, infoWindowContent, infowindow);
      }
    );
  }, [place_id, mapRef]);

  useEffect(() => {
    mapping();
  }, [mapping]);

  return (
    <>
      <div className="uk-dislpay-block">
        <div>
          <div
            id="map"
            // @ts-ignore
            ref={mapRef}
            style={{ height: "15em", width: "100%" }}
          ></div>
          <div
            id="infowindow-content"
            // @ts-ignore
            ref={infoWindowContentRef}
          >
            <img src="" width="16" height="16" id="place-icon" alt="place" />
            <span id="place-name" className="title"></span>
            <br />
            <span id="place-address"></span>
          </div>
        </div>
      </div>
    </>
  );
};

function newFunction(place, map, marker, infoWindowContent, infowindow) {
  infowindow.close();
  marker.setVisible(false);

  if (!place.geometry) {
    window.alert(`No details available for input: ${place.name}`);
    return;
  }

  if (place.geometry.viewport) {
    map.fitBounds(place.geometry.viewport);
  } else {
    map.setCenter(place.geometry.location);
    map.setZoom(17); // Why 17? Because it looks good.
  }
  marker.setPosition(place.geometry.location);
  marker.setVisible(true);

  /** */
  let address = "";
  if (place.address_components) {
    address = [
      place.address_components[0] &&
        place.address_components[0].short_name + "",
      place.address_components[1] &&
        place.address_components[1].short_name + "",
      place.address_components[2] &&
        place.address_components[2].short_name + "",
    ].join(" ");
  }

  // console.log(infoWindowContent.children);
  infoWindowContent.children["place-icon"].src = place.icon;
  infoWindowContent.children["place-name"].textContent = place.name;
  infoWindowContent.children["place-address"].textContent = address;
  infowindow.open(map, marker);
}

export function LocationAddressComponent({ location }) {
  return (
    <div className="uk-margin">
      <div className="uk-card uk-card-default uk-width-1-1@m">
        <Map
          // ref={inputRef}
          // @ts-ignore
          place_id={location.address.place_id}
        />

        <small className="uk-padding-small uk-display-block">
          {location.address.full_address}
        </small>
      </div>
    </div>
  );
}
