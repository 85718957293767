// @ts-check
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";

import { BackNavigationComponent } from "../../../../core/routes/components/back-navigation-component";

import { LoadingComponent } from "../components/loading-component";
import Grid from "@material-ui/core/Grid";
import { GuardianImageComponent } from "../components/guardian-image-component";
import { GuardianApplicationComponent } from "../components/guardian-application-component.js";
import { BackdropComponent } from "../../../../core/utils/backdrop-component";
import { SnackbarComponent } from "../../../../core/utils/snackbar-component";

import DI from "typedi";
import { GuardianActions } from "../redux/actions/guardian-actions";
const guardianActions = DI.get(GuardianActions);

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  heading: {
    margin: theme.spacing(2),
  },
}));

function GuardianComponent({ guardian }) {
  // helpers
  const [backdrop, setBackdrop] = useState(false);

  const openBackdrop = () => {
    setBackdrop(true);
  };

  const closeBackdrop = () => {
    setBackdrop(false);
  };

  const [snackbar, setSnackbar] = useState(false);
  const [message, setMessage] = useState({
    status: "success",
    description: "N/A",
  });

  const showSnackbar = (status, description) => {
    setSnackbar(true);
    setMessage({
      status,
      description,
    });
  };

  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbar(false);
  };
  return (
    <>
      <Grid container spacing={2} justify="center">
        <Grid item xs={12} md={12}>
          <GuardianImageComponent guardian={guardian} />
        </Grid>

        <Grid item xs={12} md={8}>
          <GuardianApplicationComponent
            guardian={guardian}
            openBackdrop={openBackdrop}
            closeBackdrop={closeBackdrop}
            showSnackbar={showSnackbar}
          />
        </Grid>
      </Grid>

      <BackdropComponent open={backdrop} />
      <SnackbarComponent
        open={snackbar}
        onClose={closeSnackbar}
        severity={message.status}
        message={message.description}
      />
    </>
  );
}

export default function GuardianPage() {
  // @ts-ignore
  const { id } = useParams();

  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(guardianActions.getGuardianAction(id));
  }, [dispatch, id]);

  const { guardian, loading, success, error } = useSelector(
    (state) => state.guardian
  );

  if (error) return <div>Error! {error.message}</div>;

  // return <Appointments guardians={guardians} />;
  return (
    <>
      <React.Fragment>
        <CssBaseline />
        <Container className={classes.container}>
          {(loading || !success) && <LoadingComponent />}
          {!loading && success && (
            <>
              <BackNavigationComponent
                page="Guardian"
                path={[
                  {
                    page: "Home",
                    uri: "/",
                  },
                  {
                    page: "Guardians",
                    uri: "/guardians",
                  },
                ]}
              />

              <GuardianComponent guardian={guardian} />
            </>
          )}
        </Container>
      </React.Fragment>
    </>
  );
}
