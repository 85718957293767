// @ts-check
import React, { useEffect } from "react";
import di from "typedi";
import { useDispatch, useSelector } from "react-redux";

import { BillingActions } from "../redux/actions/billing-actions";
import { LoadingComponent } from "../../../users/presentation/components/loading-component";
import { TransactionsListComponent } from "../components/transactions-list-component";
import { BillingAnalyticsGraph } from "../components/analytics/height-analytics";

export function BillingAnalytics() {
  const dispatch = useDispatch();
  const billingActions = di.get(BillingActions);
  const { transactions, success, loading, error } = useSelector(
    (state) => state.transactions
  );

  useEffect(() => {
    if (!success) {
      dispatch(billingActions.getTransactionsAction());
    }
  }, [dispatch, billingActions, success]);

  return (
    <>
      {(loading || !success) && <LoadingComponent />}
      {!loading && success && (
        <BillingAnalyticsGraph
          titleFees="Fees Paid & Refunded"
          data={transactions}
          property={"amount"}
          title="Fee Transactions"
          yName="Amount"
        />
      )}
    </>
  );
}

export function TransactionsPage() {
  const dispatch = useDispatch();
  const billingActions = di.get(BillingActions);

  useEffect(() => {
    dispatch(billingActions.getTransactionsAction());
  }, [dispatch, billingActions]);

  const { transactions, loading, success, error } = useSelector(
    (state) => state.transactions
  );

  if (error) return <div>Error! {error.message}</div>;

  return (
    <>
      {(loading || !success) && <LoadingComponent />}
      {!loading && success && (
        <>
          <div>
            <BillingAnalytics />
            <TransactionsListComponent transactions={transactions} />
          </div>
        </>
      )}
    </>
  );
}
