import fetch from "isomorphic-fetch";
import config from "../../../../core/config/config";
import store from "../../../../core/redux/store";

export const getLocation = async (location_id: string) => {
  try {
    const state = store.getState();

    const uri = `${config.uri.organization_url}/org/locations/${location_id}`;

    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token.token}`,
      },
    };

    const response = await fetch(uri, options);
    const data = await response.json();

    if (data.message) {
      throw new Error(data.message);
    }

    return data;
  } catch (err) {
    throw err;
  }
};
