// @ts-check
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Cookies from "universal-cookie";

import NavBar from "./components/nav";
import LoginPage from "../../features/authentication/presentation/pages/login-page.js";
import { AccessComponent } from "./components/access-component";

import GuardiansPage from "../../features/guardians/presentation/pages/guardians-page";
import GuardianPage from "../../features/guardians/presentation/pages/guardian-page";
import HomePage from "../../features/dashboard/presentation/pages/home-page";
import UsersPage from "../../features/users/presentation/pages/users-page";
import UserPage from "../../features/users/presentation/pages/user-page";
import { ProfilePage } from "../../features/me/presentation/pages/profile-page";

import OrganizationPage from "../../features/organization/presentation/pages/organization-page";
import CreateLocationPage from "../../features/locations/presentation/pages/create-location-page";
import LocationPage from "../../features/locations/presentation/pages/location-page";
import LocationsPage from "../../features/locations/presentation/pages/locations-page";
import BillingPage from "../../features/billing/presentation/pages/billing-page";
import EditPayoutPage from "../../features/billing/presentation/pages/edit-payout-page";

function Protected() {
  return (
    <AccessComponent>
      <Switch>
        {/* <Route path="/appointment/:id" component={ViewAppointmentComponent} /> */}

        <Route>
          <NavBar>
            <Route>
              {/* <Route exact path="/" component={DashboardPage} /> */}
              <Route exact path="/" component={HomePage} />
              <Route exact path="/guardians" component={GuardiansPage} />
              <Route exact path="/guardians/:id" component={GuardianPage} />

              <Route exact path="/billing" component={BillingPage} />
              <Route
                exact
                path="/billing/edit/payout/details"
                component={EditPayoutPage}
              />

              <Route exact path="/users" component={UsersPage} />
              <Route exact path="/users/:id" component={UserPage} />

              <Route path="/organization" component={OrganizationPage} />

              <Route path="/account" component={ProfilePage} />

              <Route exact path="/locations" component={LocationsPage} />
              <Route
                exact
                path="/locations/create"
                component={CreateLocationPage}
              />
              <Route path="/locations/:id/view" component={LocationPage} />

              {/* <Route exact path="/" render={() => <Redirect to="/account" />} /> */}
            </Route>
          </NavBar>
        </Route>
      </Switch>
    </AccessComponent>
  );
}

function Routes() {
  return (
    <Router>
      <Switch>
        <Route exact path="/login" component={LoginPage} />

        <Route
          render={() => {
            const cookies = new Cookies();
            const token = cookies.get("gh_org_tok");

            if (!token) {
              return <Redirect to="/login" />;
            }

            return <Protected />;
          }}
        />
      </Switch>
    </Router>
  );
}

export default Routes;
