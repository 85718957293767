// @ts-check
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import IconButton from "@material-ui/core/IconButton";

import Menu from "@material-ui/core/Menu";
import AppsOutlinedIcon from "@material-ui/icons/AppsOutlined";
import { Products } from "./products";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

const logoIcon = `https://storage.googleapis.com/guardian-health-brand-identity/organization-icon.png`;

const styles = {
  nav: {
    background: "white",
    boxShadow: "rgba(41, 70, 97, 0.15) 0px 1px 3px",
    // backgroundColor: '#232a34'
  },
  logo: {
    height: "3.2em",
    objectFit: "contain",
  },
  logoText: {
    height: "3.2em",
    objectFit: "contain",
  },
  profile: {
    height: "2em",
    width: "2em",
    objectFit: "cover",
  },
  icon: {
    height: "1.65em",
  },
};

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(0),
  },
  logo: {
    // height: "1.9rem",
    height: "3.0em",
    objectFit: "contain",
  },
  edgePadding: {
    padding: theme.spacing(0),
  },
  section: {
    display: "flex",
  },
  icon: {
    height: "1.9rem",
    // width: '8rem',
    objectFit: "contain",
  },
  divider: {
    // marginRight: "8px !important",
    // marginLeft: "8px !important",
    margin: "0.8rem 8px !important",
  },
}));

export default function MainNav(props) {
  return (
    <>
      <NavBar />
      {props.children}
    </>
  );
}

export function NavBar() {
  const { me } = useSelector((state) => state.me);
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <Products />
    </Menu>
  );

  return (
    <>
      <div className="" style={styles.nav} data-uk-sticky>
        <nav className="uk-container" data-uk-navbar>
          <div className="uk-navbar-left">
            <ul className="uk-navbar-nav">
              <li>
                <Link to="/">
                  <img
                    // className="uk-visible@s"
                    src={logoIcon}
                    alt="Guardian Health"
                    // @ts-ignore
                    style={styles.logoText}
                  />
                  <Divider
                    orientation="vertical"
                    flexItem
                    className={classes.divider}
                  />
                  <img
                    className={classes.logo}
                    src={me.organization.logo}
                    // src="https://www.netcare.co.za/Portals/0/NetcareLogo.png?ver=2020-04-03-092822-653"
                    alt="logo"
                  />
                </Link>
              </li>
            </ul>
          </div>

          <div className="uk-navbar-right">
            <ul className="uk-navbar-nav">
              <li>
                {/* eslint-disable-next-line */}
                <a
                  className="uk-padding-remove-left uk-padding-remove-right"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <IconButton
                    aria-label="show 4 new mails"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={handleProfileMenuOpen}
                    color="inherit"
                  >
                    <AppsOutlinedIcon />
                  </IconButton>
                </a>
              </li>

              <li>
                <Link
                  to="/account/options"
                  className="uk-padding-remove-left- "
                >
                  <div className="uk-text-center">
                    <img
                      className="uk-border-circle"
                      // @ts-ignore
                      src={me && me.image}
                      alt="Guardian Health"
                      // @ts-ignore
                      style={styles.profile}
                    />
                    <ArrowDropDownIcon />
                  </div>
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>

      {renderMenu}
    </>
  );
}
