const APP_ENV = process.env.REACT_APP_ENV;

const dev_urls = {
  auth_url: `https://guardian-health-authentication-middleware-gk7dnsmexq-ew.a.run.app`,
  graph_url: `https://guardian-health-graphql-gk7dnsmexq-ew.a.run.app/graphiql`,
  rest_url: `https://guardian-health-graphql-gk7dnsmexq-ew.a.run.app`,
  payment_url: `https://gh-payments-api-gk7dnsmexq-ew.a.run.app`,
  upload_url: "https://gh-upload-api-gk7dnsmexq-ew.a.run.app",
  health_pro_app: `//guardian-health-medical-guardians-gk7dnsmexq-ew.a.run.app`,
  health_app: `//guardian-health-gk7dnsmexq-ew.a.run.app`,
  frontdesk_app: `//gh-frontdesk-gk7dnsmexq-ew.a.run.app`,
  organization_app: `//gh-organization-gk7dnsmexq-ew.a.run.app`,
  pharmaceutical_url: `https://gh-pharmaceutical-api-gk7dnsmexq-ew.a.run.app`,
  prescription_url: `https://gh-prescription-api-gk7dnsmexq-ew.a.run.app`,
  admin_url: `https://gh-admin-api-gk7dnsmexq-ew.a.run.app`,
  billing_url: `https://gh-billing-api-gk7dnsmexq-ew.a.run.app`,
  guardians_url: `https://gh-guardians-api-gk7dnsmexq-ew.a.run.app`,
  organization_url: `https://gh-organization-api-gk7dnsmexq-ew.a.run.app`,
  appointments_url: `https://gh-appointments-api-gk7dnsmexq-ew.a.run.app`,
};

const prod_urls = {
  auth_url: `https://authentication.guardianhealth.io`,
  graph_url: `https://graph.guardianhealth.io/graphiql`,
  rest_url: `https://graph.guardianhealth.io`,
  payment_url: `https://payments.guardianhealth.io`,
  upload_url: "https://gh-upload-api-w75wgncdrq-ew.a.run.app",
  health_pro_app: `//app.guardianhealth.io`,
  health_app: `//guardianhealth.io`,
  frontdesk_app: `//frontdesk.guardianhealth.io`,
  organization_app: `//organization.guardianhealth.io`,
  pharmaceutical_url: `https://gh-pharmaceutical-api-w75wgncdrq-ew.a.run.app`,
  prescription_url: `https://gh-prescription-api-w75wgncdrq-ew.a.run.app`,
  admin_url: `https://gh-admin-api-w75wgncdrq-ew.a.run.app`,
  billing_url: `https://gh-billing-api-w75wgncdrq-ew.a.run.app`,
  guardians_url: `https://gh-guardians-api-w75wgncdrq-ew.a.run.app`,
  organization_url: `https://gh-organization-api-w75wgncdrq-ew.a.run.app`,
  appointments_url: `https://gh-appointments-api-w75wgncdrq-ew.a.run.app`,
};

const app_images = {
  pro_icon_logo: `https://storage.googleapis.com/guardian-health-brand-identity/medical-guardians-icon-logo.png`,
  icon_logo: `https://storage.googleapis.com/guardian-health-brand-identity/guardian-health-icon-logo.png`,
  text_logo: `https://storage.googleapis.com/guardian-health-brand-identity/guardian-health-text-logo.png`,
  text_only_logo: `https://storage.googleapis.com/guardian-health-brand-identity/guardian-health-text-only.png`,
  pro_text_only: `https://storage.googleapis.com/guardian-health-brand-identity/medical-guardians-text-dull-logo.png`,
};

const images = {
  backgroundimage: `https://storage.googleapis.com/guardian-health-brand-identity/site-images/heart-background.jpg`,
  inperson: `https://storage.googleapis.com/guardian-health-brand-identity/site-images/type-inperson.jpg`,
  video: `https://storage.googleapis.com/guardian-health-brand-identity/site-images/type-video-second.jpg`,
  afterhours: `https://storage.googleapis.com/guardian-health-brand-identity/site-images/type-afterhours.jpg`,
  health_future: `https://storage.googleapis.com/guardian-health-brand-identity/site-images/guardian-health-future-min.jpg`,
  teledoctor: `https://storage.googleapis.com/guardian-health-brand-identity/site-images/main-image.jpg`,
  patient: `https://storage.googleapis.com/guardian-health-brand-identity/site-images/patient.jpeg`,
  practice: `https://storage.googleapis.com/guardian-health-brand-identity/site-images/practice.jpeg`,
  smart_prescription: `https://storage.googleapis.com/guardian-health-brand-identity/site-images/smart-prescription.jpg`,
  prescription_rx: `https://storage.googleapis.com/guardian-health-brand-identity/site-images/prescription-rx.jpg`,
  smart_notes: `https://storage.googleapis.com/guardian-health-brand-identity/site-images/smart-notes.jpg`,
  patient_md_record: `https://storage.googleapis.com/guardian-health-brand-identity/site-images/patient-md-record.jpg`,
  app_eg: `https://storage.googleapis.com/guardian-health-brand-identity/site-images/app.png`,

  // background: `https://storage.googleapis.com/guardian-health-brand-identity/site-images/`,
  pregnant: `https://storage.googleapis.com/guardian-health-brand-identity/site-images/pregnant.jpeg`,
  foetus: `https://storage.googleapis.com/guardian-health-brand-identity/site-images/41-1.jpg`,
  ventureburn: `https://storage.googleapis.com/guardian-health-brand-identity/site-images/venture-burn.png`,
  mybroadband: `https://storage.googleapis.com/guardian-health-brand-identity/site-images/mybroadband.svg`,
  itweb: `https://storage.googleapis.com/guardian-health-brand-identity/site-images/itweb.png`,
  techafrica: `https://storage.googleapis.com/guardian-health-brand-identity/site-images/logo-tech-startups-africa-retina-1.png`,
  cnbcafrica: `https://storage.googleapis.com/guardian-health-brand-identity/site-images/1200px-CNBC_Africa.svg_-min.png`,
  pulse: `https://storage.googleapis.com/guardian-health-brand-identity/site-images/`,
  // cartoons
  mtn: `https://storage.googleapis.com/guardian-health-brand-identity/mtn.png`,
  cdesign: `https://storage.googleapis.com/guardian-health-brand-identity/cartoon/design.png`,
  cconsult: `https://storage.googleapis.com/guardian-health-brand-identity/cartoon/4.png`,
  crecord: `https://storage.googleapis.com/guardian-health-brand-identity/cartoon/1.png`,
  cprep: `https://storage.googleapis.com/guardian-health-brand-identity/cartoon/prep.png`,
  clab: `https://storage.googleapis.com/guardian-health-brand-identity/cartoon/2.png`,
};

const config = {
  token: undefined,
  logo: "http://cowabunga.co.za/static/img/logo.svg",
  app_images: app_images,
  images: images,
  uri: APP_ENV === "production" ? prod_urls : dev_urls,
};

export default config;
