// @ts-check
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import { BarChart, BarChart1, DornutStats } from "./charts";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0),
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  sectionHeading: {
    margin: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    cursor: "pointer",
  },
  container: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  clientLogo: {
    height: "8rem",
    width: "8rem",
    objectFit: "contain",
  },
  icon: {
    fontSize: "4em",
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  heading: {
    margin: theme.spacing(2),
  },
}));

export function BillingAnalyticsGraph({
  data,
  property,
  title,
  yName,
  revenue = true,
  titleFees,
}) {
  const classes = useStyles();

  const groups = {
    critical: [],
    severe: [],
    normal: [],
  };

  const plotData = {
    categories: [],
    data: [],
    fees: [],
    booking: [],
    service: [],
    cancellation: [],
    reversal: [],
    payment: [],
  };

  data.map((point) => {
    // @ts-ignore
    plotData.categories.push(point.datetime);
    // @ts-ignore

    plotData.data.push([new Date(point.created_at).getTime(), point[property]]);

    const arr = [new Date(point.created_at).getTime(), point[property]];

    if (
      ["bookingfee", "servicefee", "cancellation_fee", "reversefee"].includes(
        point.task
      )
    ) {
      // @ts-ignore
      plotData.fees.push(arr);
    }

    if (["bookingfee"].includes(point.task)) {
      // @ts-ignore
      plotData.booking.push(arr);
    }

    if (["servicefee"].includes(point.task)) {
      // @ts-ignore
      plotData.service.push(arr);
    }

    if (["cancellation_fee"].includes(point.task)) {
      // @ts-ignore
      plotData.cancellation.push(arr);
    }

    if (["reversefee"].includes(point.task)) {
      // @ts-ignore
      plotData.reversal.push(arr);
    }

    if (["payment"].includes(point.task)) {
      // @ts-ignore
      plotData.payment.push(arr);
    }

    return point.datetime;
  });

  const pie_stats = [
    [],
    [
      "Booking Fees",
      Math.abs(
        parseFloat(
          plotData.booking
            .map((x) => x[1])
            .reduce((a, b) => a + b, 0)
            .toFixed(2)
        )
      ),
    ],
    [
      "Service Fees",
      Math.abs(
        parseFloat(
          plotData.service
            .map((x) => x[1])
            .reduce((a, b) => a + b, 0)
            .toFixed(2)
        )
      ),
    ],
    [
      "Cancellation Fees",
      Math.abs(
        parseFloat(
          plotData.cancellation
            .map((x) => x[1])
            .reduce((a, b) => a + b, 0)
            .toFixed(2)
        )
      ),
    ],
    [
      "Reversed Fees",
      Math.abs(
        parseFloat(
          plotData.reversal
            .map((x) => x[1])
            .reduce((a, b) => a + b, 0)
            .toFixed(2)
        )
      ),
    ],
  ];

  return (
    <div className="uk-margin">
      <Grid container spacing={2}>
        {revenue && (
          <Grid item xs={12} md={12}>
            <div className="uk-card uk-card-default">
              <Paper className={classes.paper} elevation={0}>
                <BarChart1
                  title={"Online Revenue"}
                  subtitle={"For the next 7 days"}
                  xy={plotData}
                  yName={yName}
                />
              </Paper>
            </div>
          </Grid>
        )}

        <Grid item xs={12} md={12}>
          <div className="uk-card uk-card-default">
            <Paper className={classes.paper} elevation={0}>
              <DornutStats data={pie_stats} name={titleFees} />
            </Paper>
          </div>
        </Grid>

        <Grid item xs={12} md={12}>
          <div className="uk-card uk-card-default">
            <Paper className={classes.paper} elevation={0}>
              <BarChart
                title={title}
                subtitle={"For the next 7 days"}
                xy={plotData}
                yName={yName}
              />
            </Paper>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
