interface IBankDetails {
  status: string;
  bank_name: string;
  account_no: string;
  branch_code: string;
  account_holder: string;
}

class BankDetails implements IBankDetails {
  status: string;
  bank_name: string;
  account_no: string;
  branch_code: string;
  account_holder: string;

  constructor(args: any) {
    this.status = args.status;
    this.bank_name = args.bank_name;
    this.account_no = args.account_no;
    this.branch_code = args.branch_code;
    this.account_holder = args.account_holder;
  }
}

interface IAccount {
  id: string;
  currency: string;
  balance: number;
  status: string;
  bank_details?: IBankDetails;
}

export class Account implements IAccount {
  id: string;
  currency: string;
  balance: number;
  status: string;
  bank_details?: IBankDetails;

  constructor(id: string, currency: string, balance: number, status: string, bank_details?: any) {
    this.id = id;
    this.currency = currency;
    this.balance = balance;
    this.status = status;
    this.bank_details = bank_details && new BankDetails(bank_details);
  }
}
