// @ts-check
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import CssBaseline from "@material-ui/core/CssBaseline";

import { LoadingComponent } from "../components/loading-component";

import { getUsersAction } from "../redux/actions/user-actions";
import { UsersList } from "../components/users-list-component";

export default function UsersLocationPage({ location_id }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsersAction(location_id));
  }, [dispatch, location_id]);

  const { users, loading, error } = useSelector((state) => state.users);

  if (error) return <div>Error! {error.message}</div>;

  return (
    <>
      <React.Fragment>
        <CssBaseline />

        {loading && <LoadingComponent />}

        {!loading && users && (
          <>
            <UsersList users={users} />
          </>
        )}
      </React.Fragment>
    </>
  );
}
