import { Dispatch } from "redux";
import { Service } from "typedi";
import { Api } from "../../../../../core/api/api";
import {
  LOADING_FAILURE,
  LOADING_REQUEST,
  LOADING_SUCCESS,
} from "../../../../../core/redux/types/loading-types";
import { BillingRepositoryImpl } from "../../../data/repositories/billing-repository-impl";

import {
  BillingUsecase,
  BillingUsecaseImpl,
} from "../../../domain/usecases/billing-usecase";
import {
  BILLING_LOAD_FAILURE,
  BILLING_LOAD_REQUEST,
  BILLING_LOAD_SUCCESS,
} from "../types/billing-types";
import {
  TRANSACTIONS_LOAD_FAILURE,
  TRANSACTIONS_LOAD_REQUEST,
  TRANSACTIONS_LOAD_SUCCESS,
} from "../types/transactions-types";

@Service()
export class BillingActions {
  private readonly billingUsecase: BillingUsecase;

  constructor(billingUsecase: BillingUsecase) {
    console.log(billingUsecase);
    console.log("billingUsecase");

    const api = new Api();
    const billingRepository = new BillingRepositoryImpl(api);
    this.billingUsecase = new BillingUsecaseImpl(billingRepository);
  }

  getAccountBalanceAction = () => {
    return async (dispatch: Dispatch) => {
      dispatch({ type: BILLING_LOAD_REQUEST });

      try {
        const result = await this.billingUsecase.GetAccount();

        dispatch({ type: BILLING_LOAD_SUCCESS, payload: result });
      } catch (error) {
        console.log(error);
        dispatch({ type: BILLING_LOAD_FAILURE, error });
      }
    };
  };

  getTransactionsAction = () => {
    return async (dispatch: Dispatch) => {
      dispatch({ type: TRANSACTIONS_LOAD_REQUEST });

      try {
        const result = await this.billingUsecase.GetTransactions();

        dispatch({ type: TRANSACTIONS_LOAD_SUCCESS, payload: result });
      } catch (error) {
        console.log(error);
        dispatch({ type: TRANSACTIONS_LOAD_FAILURE, error });
      }
    };
  };

  editPaypoutBankDetailsAction = (
    bank_name: string,
    account_no: string,
    branch_code: string,
    account_holder: string,
    props: any
  ) => {
    return async (dispatch: Dispatch) => {
      dispatch({ type: LOADING_REQUEST });

      try {
        const result = await this.billingUsecase.UpdatePayoutBankDetails(
          bank_name,
          account_no,
          branch_code,
          account_holder
        );

        dispatch({ type: BILLING_LOAD_SUCCESS, payload: result });
        dispatch({ type: LOADING_SUCCESS, payload: result });
        props.resetForm();
      } catch (error) {
        dispatch({ type: LOADING_FAILURE, error });
      }
    };
  };
}
