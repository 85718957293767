import { Service } from "typedi";
import fetch from "isomorphic-fetch";
import store from "../redux/store";

export interface IApi {
  GetToken(): string;
  Get(uri: string): Promise<any>;
  Post(uri: string, body?: any): Promise<any>;
}

@Service()
export class Api implements IApi {
  GetToken(): string {
    const state = store.getState();

    return state.token.token;
  }

  async Get(uri: string): Promise<any> {
    try {
      const token: string = this.GetToken();

      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(uri, options);
      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.message || "Something went wrong");
      }

      return result;
    } catch (err) {
      throw err;
    }
  }

  async Post(uri: string, body?: any): Promise<any> {
    try {
      const token: string = this.GetToken();

      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      };

      const response = await fetch(uri, options);
      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.message || "Something went wrong");
      }

      return result;
    } catch (err) {
      throw err;
    }
  }
}
