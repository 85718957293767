// @ts-check
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import di from "typedi";

import { Formik } from "formik";
import * as Yup from "yup";

import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";

import { LoadingButton } from "../../../../core/constants";
import { BillingActions } from "../redux/actions/billing-actions";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  sectionHeading: {
    margin: theme.spacing(2),
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  formButtons: {
    marginTop: theme.spacing(2),
  },
  errorText: {
    color: "#f44336",
    textAlign: "center",
    display: "block",
    margin: theme.spacing(3),
  },
}));

function PayoutDetailsForm(props) {
  const classes = useStyles();

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
    props;

  /**
   * @param {string} name
   */
  const formikProps = (name, initialValue = "") => ({
    name: name,
    value: typeof values[name] !== "undefined" ? values[name] : initialValue,
    onChange: handleChange,
    onBlur: handleBlur,
    error: touched[name] && Boolean(errors[name]),
    helperText: touched[name] ? errors[name] : "",
  });

  const { loading, error } = useSelector((state) => state.loading);

  return (
    <form
      className={classes.root}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <TextField
        {...formikProps("bank_name")}
        id="outlined-full-width"
        label="Bank Name"
        placeholder="Bank Name e.g FNB"
        type="text"
        variant="outlined"
        fullWidth={true}
      />

      <TextField
        {...formikProps("account_no")}
        id="outlined-full-width"
        label="Account No."
        type="text"
        variant="outlined"
        fullWidth={true}
      />

      <TextField
        {...formikProps("branch_code")}
        id="outlined-full-width"
        label="Branch Code"
        type="text"
        variant="outlined"
        fullWidth={true}
      />

      <TextField
        {...formikProps("account_holder")}
        id="outlined-full-width"
        label="Account Holder"
        type="text"
        variant="outlined"
        fullWidth={true}
      />

      <div className={classes.formButtons}>
        {!loading && (
          <div>
            <button
              className="uk-button uk-button-primary uk-width-1-1 uk-margin"
              type="submit"
            >
              Save
            </button>
          </div>
        )}

        {loading && <LoadingButton />}

        {error && (
          <div>
            <span className={classes.errorText}>{error.message}</span>
          </div>
        )}
      </div>
    </form>
  );
}

const validationSchema = Yup.object({
  bank_name: Yup.string().required("Bank name is required"),
  account_no: Yup.string().required("Bank account no. is required"),
  branch_code: Yup.string().required("Bank branch code is required"),
  account_holder: Yup.string().required("Account holder name is required"),
});

export function PayoutDetailsFormComponent({ setViewForm }) {
  const dispatch = useDispatch();
  const billingActions = di.get(BillingActions);

  const values = {
    bank_name: "",
    account_no: "",
    branch_code: "",
    account_holder: "",
  };

  const submit = async (data, actions) => {
    const { resetForm } = actions;

    const props = { setViewForm, resetForm };

    dispatch(
      billingActions.editPaypoutBankDetailsAction(
        data.bank_name,
        data.account_no,
        data.branch_code,
        data.account_holder,
        props
      )
    );
  };

  return (
    <>
      <div className="uk-margin">
        <Formik
          initialValues={values}
          validationSchema={validationSchema}
          onSubmit={submit}
        >
          {(props) => <PayoutDetailsForm {...props} />}
        </Formik>
      </div>
    </>
  );
}
