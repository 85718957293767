// @ts-check
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import {
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";

import IconButton from "@material-ui/core/IconButton";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import DeleteIcon from "@material-ui/icons/Delete";
import { updateUserPermissionAction } from "../redux/actions/user-actions";

import { SnackbarComponent } from "../../../../core/utils/snackbar-component";
import { BackdropComponent } from "../../../../core/utils/backdrop-component";
import { AddUserLocationFormComponent } from "./add-user-location-from-component";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(2),
  },
  inline: {
    display: "inline",
  },
  heading: {
    margin: theme.spacing(2),
  },
  fab: {
    width: "100%",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  granted: {
    color: "#4caf50",
  },
}));

function Location({ permission, revoke }) {
  const classes = useStyles();

  return (
    <>
      <div className="uk-margin">
        <List className={classes.root}>
          <ListItem>
            <ListItemIcon>
              <LocationOnIcon />
            </ListItemIcon>

            <ListItemText
              primary={`${permission.location.name}`}
              secondary={null}
            ></ListItemText>

            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                onClick={() => revoke(permission.location.id, permission.roles)}
              >
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </div>
    </>
  );
}

export function LocationPermissionsComponent({ user }) {
  const dispatch = useDispatch();

  const [backdrop, setBackdrop] = useState(false);

  const openBackdrop = () => {
    setBackdrop(true);
  };

  const closeBackdrop = () => {
    setBackdrop(false);
  };

  const [snackbar, setSnackbar] = useState(false);
  const [message, setMessage] = useState({
    status: "success",
    description: "N/A",
  });

  const showSnackbar = (status, description) => {
    setSnackbar(true);
    setMessage({
      status,
      description,
    });
  };

  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbar(false);
  };

  const grant = (location_id, roles, resetForm) => {
    openBackdrop();
    const props = { closeBackdrop, showSnackbar, resetForm };
    const level = "location";
    const type = "grant";
    const permission = {
      location_id,
      roles,
    };
    dispatch(
      updateUserPermissionAction(user.id, level, type, permission, props)
    );
  };

  const revoke = (location_id, roles) => {
    openBackdrop();

    const props = { closeBackdrop, showSnackbar };
    const level = "location";
    const type = "revoke";
    const permission = {
      location_id,
      roles,
    };
    dispatch(
      updateUserPermissionAction(user.id, level, type, permission, props)
    );
  };

  return (
    <>
      <AddUserLocationFormComponent grant={grant} />

      {user.permissions.map((x, key) => (
        <Location key={key} permission={x} revoke={revoke} />
      ))}

      <>
        <BackdropComponent open={backdrop} />

        <SnackbarComponent
          open={snackbar}
          onClose={closeSnackbar}
          severity={message.status}
          message={message.description}
        />
      </>
    </>
  );
}
