import { Dispatch } from "redux";

import {
  LOADING_FAILURE,
  LOADING_REQUEST,
  LOADING_SUCCESS,
} from "../../../../../core/redux/types/loading-types";
import { LocationRepository } from "../../../data/repositories/location-repository";
import { LocationUsecase } from "../../../domain/usecases/location-usecase";
import {
  LOCATION_LOAD_FAILURE,
  LOCATION_LOAD_REQUEST,
  LOCATION_LOAD_SUCCESS,
} from "../types/location-types";
import {
  LOCATIONS_LOAD_FAILURE,
  LOCATIONS_LOAD_REQUEST,
  LOCATIONS_LOAD_SUCCESS,
} from "../types/locations-types";

export const getLocationsAction = () => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: LOCATIONS_LOAD_REQUEST });

    try {
      const locationRepository = new LocationRepository();
      const locationUsecase = new LocationUsecase(locationRepository);

      const result = await locationUsecase.GetLocations();

      dispatch({ type: LOCATIONS_LOAD_SUCCESS, payload: result });
    } catch (error) {
      dispatch({ type: LOCATIONS_LOAD_FAILURE, error });
    }
  };
};

export const getLocationAction = (location_id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: LOCATION_LOAD_REQUEST });

    try {
      const locationRepository = new LocationRepository();
      const locationUsecase = new LocationUsecase(locationRepository);

      const result = await locationUsecase.GetLocation(location_id);

      dispatch({ type: LOCATION_LOAD_SUCCESS, payload: result });
    } catch (error) {
      dispatch({ type: LOCATION_LOAD_FAILURE, error });
    }
  };
};

export const createLocationAction = (
  name: string,
  address: any,
  props: any
) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: LOADING_REQUEST });

    try {
      const locationRepository = new LocationRepository();
      const locationUsecase = new LocationUsecase(locationRepository);

      const result = await locationUsecase.CreateLocation(name, address);

      dispatch({ type: LOADING_SUCCESS });
      dispatch({ type: LOCATION_LOAD_SUCCESS, payload: result });
    } catch (error) {
      dispatch({ type: LOADING_FAILURE, error });
    }
  };
};
