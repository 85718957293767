// @ts-check
import React, { useEffect } from "react";
import di from "typedi";
import { useDispatch, useSelector } from "react-redux";

import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";

import { BackNavigationComponent } from "../../../../core/routes/components/back-navigation-component";

import Grid from "@material-ui/core/Grid";

import { TextBackgroundComponent } from "../../../locations/presentation/components/text-background-component";

import { BillingActions } from "../redux/actions/billing-actions";
import { LoadingComponent } from "../../../users/presentation/components/loading-component";

import { BankPayoutDetailsComponent } from "../components/payout-details-component";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  heading: {
    margin: theme.spacing(2),
  },
}));

export default function EditPayoutPage() {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    const billingActions = di.get(BillingActions);

    dispatch(billingActions.getAccountBalanceAction());
  }, [dispatch]);

  const { billing, loading, success, error } = useSelector(
    (state) => state.billing
  );

  if (error) return <div>Error! {error.message}</div>;

  return (
    <>
      <React.Fragment>
        <CssBaseline />
        <Container className={classes.container}>
          {(loading || !success) && <LoadingComponent />}
          {!loading && success && (
            <>
              <BackNavigationComponent
                page="Payout Details"
                path={[
                  {
                    page: "Home",
                    uri: "/",
                  },
                  {
                    page: "Billing",
                    uri: "/billing",
                  },
                ]}
              />

              <Grid container spacing={2} justify="center">
                <Grid item xs={12} md={12}>
                  <TextBackgroundComponent text={"Edit Payout Details"} />
                </Grid>
                <Grid item xs={12} md={8}>
                  <BankPayoutDetailsComponent payout={billing.bank_details} />
                </Grid>
              </Grid>
            </>
          )}
        </Container>
      </React.Fragment>
    </>
  );
}
