import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import token from "./reducers/token-reducers";
import loading from "./reducers/loading-reducers";

import login from "../../features/authentication/presentation/redux/reducers/login-reducers";
import orgs from "../../features/authentication/presentation/redux/reducers/organizations-reducers";

import me from "../../features/me/presentation/redux/reducers/me-reducer";
// import upload from "../../features/me/presentation/redux/reducers/upload-reducer";

import guardian from "../../features/guardians/presentation/redux/reducers/guardian-reducer";
import guardians from "../../features/guardians/presentation/redux/reducers/guardians-reducer";

import user from "../../features/users/presentation/redux/reducers/user-reducer";
import users from "../../features/users/presentation/redux/reducers/users-reducer";

import organization from "../../features/organization/presentation/redux/reducers/organization-reducer";
import upload from "../../features/organization/presentation/redux/reducers/upload-reducer";
import find_users from "../../features/users/presentation/redux/reducers/find-users-reducer";

import location from "../../features/locations/presentation/redux/reducers/location-reducer";
import locations from "../../features/locations/presentation/redux/reducers/locations-reducer";

import billing from "../../features/billing/presentation/redux/reducers/billing-reducer";
import transactions from "../../features/billing/presentation/redux/reducers/transactions-reducer";

import analytics from "../../features/analytics/presentation/redux/reducers/analytics-reducer";

const reducers = combineReducers({
  token,
  login,
  orgs,
  loading,
  me,
  guardians,
  guardian,
  users,
  user,
  organization,
  upload,
  find_users,
  location,
  locations,
  billing,
  transactions,
  analytics,
});

const store = createStore(reducers, applyMiddleware(thunk));

export default store;
